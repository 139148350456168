
import { Vue, Component, Prop, Emit,Watch } from 'vue-property-decorator';
import moment from 'moment'
import { translate } from '@/helpers/functions';

@Component
export default class ChatRecent extends Vue {
  @Prop({ default: false }) chat!: any;
  @Prop({ default: false }) isActive!: boolean;

  get messageContainsDocument()
  {
    return this.chat.latestMessage.files.length > 0
  }
  get messageContainsImage()
  {
    return this.chat.latestMessage.images.length > 0
  }
  get messageContainsText()
  {
      return this.chat.latestMessage.content.length > 0
  }
  get activeClass() {
    return this.isActive ? 'active' : '';
  }

  @Emit('updateActiveChat')
  updateActiveChat() {
    return this.chat.id
  }

  get lastMessageAt() {
    return this.chat.lastMessageAt;
  }

  get lastMessage() {
      return this.chat.latestMessage.content

  }

  get name() {
    return Vue.prototype.translate(this.chat.receiverUser.firstName) + ' ' + Vue.prototype.translate(this.chat.receiverUser.lastName)
  }

  get unreadStatus() {
    return this.chat.unreadMessages > 0 ? 'font-bold' : '';
  }

  get avatar(){
    return this.chat.senderUser?.image?.path
  }
  getDefaultAvatarImg()
  {
    return "/img/avatar.png"
  }
}
