
import {Component, Vue, Prop} from 'vue-property-decorator';
import TabManager from '@/mixins/TabManager';
import ItemsIndex from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import stores from "@/stores";
import {showToaster} from "@/components/DashboardComposer/helpers";
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronInput from "@/components/NevronInput.vue";
import NevronTextbox from "@/components/NevronTextbox.vue";
import {translate} from "@/helpers/functions";

@Component({
  methods: {translate},
  computed: {
    stores() {
      return stores
    }
  },
  components: {
    NevronTextbox,
    NevronInput,
    NevronHeader,
    NevronFooter,
    ItemsIndex,
  },
})
export default class RSSIndex extends TabManager {

  module!: IModule;

  get TAB_KEYS() {
    return ['rss-items', 'settings'];
  }

  mounted() {
    this.getModuleData();
  }

  getModuleData() {
    stores.modules.getSpecificModule('RSS')
      .then((response) => {
        if (response.length > 0) {
          this.module = response[0];
        }
      });
  }
  submitForm() {
    const data = {
      name: this.module.name,
      description: this.module.description
    }
    stores.modules.updateItem(this.module.id, data)
      .then((response) => {
        if (response.success) {
          showToaster('success', '', 'Module update successfully');
          this.module = response.data;
        }
      })
  }
}
