
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';
import stores from '@/stores';
import { showToaster } from "@/components/DashboardComposer/helpers";
import TimeZones from "@/modules/TimeZones.vue";
import { bus } from '@/helpers/functions';
import MultiSelect from 'vue-multiselect';
import {RolesEnum} from "@/enums/RolesEnum";

@Component({
  computed: {
    stores() {
      return stores
    }
  },
  components: { TimeZones, SweetModal, MultiSelect }
})
export default class SuperAdminDetails extends Vue {
  @Prop({ required: true }) userData!: ISuperAdmin;

  currentPassword: string = '';
  adminProfile: any = { profileImageUrl: null, name: '' };
  showAllSessions: boolean = false;
  allProjects: IProject[] = [];
  selectedProjects: IProject[] = [];

  async mounted() {
    let projects = await stores.master.getAllProjects();
    if(projects.length > 0)
    {
      this.allProjects.push({
        'id' : 'all_projects',
        'name' : 'All Projects'
      });
    }
    projects.forEach((project: IManagerProject) => {
      this.allProjects.push({ id : project.id, name : project.name as string });
    });
  }
  get isProjectAdmin(): boolean {
    return this.adminProfile.role === RolesEnum.PROJECT_ADMIN;
  }

  get isSuperAdmin(): boolean {
    return stores.admin.role === RolesEnum.NEVRON_SUPERADMIN;
  }
  get isAllowedRemoveProject(): boolean {
    return this.isSuperAdmin && !this.adminProfile.futureProjects;
  }
  @Watch('userData', { immediate: true })
  onProfileToEditChange(newValue: ISuperAdmin) {
    this.initProfileData({...newValue});
  }
  initProfileData(newValue: ISuperAdmin) {
    this.adminProfile = null;
    this.adminProfile = {...newValue};
    if (this.adminProfile && this.adminProfile.projects) {
      this.selectedProjects = this.adminProfile.projects.map((project: any) => ({ id: project.id, name: project.name }));
    }
  }

  get adminProfileInitials() {
    if (!this.adminProfile?.name) return '';
    const initials = this.adminProfile.name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  get currentTime() {
    return new Date();
  }

  get filteredProjects(): IProject[] {
    return this.allProjects.filter(
      (project: any) => !this.selectedProjects.some((selected: any) => selected.id === project.id)
    );
  }

  openFileExplorer() {
    const fileInput = this.$refs.fileInput as HTMLInputElement;
    fileInput?.click();
  }

  uploadImage(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files?.[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        if (this.adminProfile) {
          this.adminProfile.profileImageUrl = reader.result as string;
        }
        fileInput.value = '';
      };
      reader.readAsDataURL(fileInput.files[0]);
    }
  }

  get profileImageStyle() {
    return this.adminProfile?.profileImageUrl ? {
      backgroundImage: `url(${this.adminProfile.profileImageUrl})`,
      cursor: 'pointer',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '68px',
      height: '68px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    } : {
      cursor: 'pointer',
      width: '68px',
      height: '68px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#007bff',
      color: '#fff',
      fontSize: '20px'
    };
  }

  async onSubmit() {
    if (!this.validateForm() || !await this.validatePassword()) {
      return;
    }
    this.adminProfile.projects = this.selectedProjects;
    return stores.SuperAdmins.updateItem(this.adminProfile)
      .then(() => {
        showToaster('success', '', 'User successfully updated.');
        this.closeModal();
      })
      .catch((e) => console.error(e));
  }

  formatTime(time: Date, format: string): string {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const formattedHours = format === '12' ? (hours % 12 || 12) : hours;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const ampm = format === '12' ? (hours >= 12 ? 'PM' : 'AM') : '';
    return `${formattedHours}:${formattedMinutes} ${ampm}`.trim();
  }

  formatDate(date: Date, format: string): string {
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return format === 'dmy' ? `${day} ${monthName}, ${year}` : format === 'mdy' ? `${monthName} ${day}, ${year}` : '';
  }

  closeModal() {
    (this.$refs.adminProfile as any)?.close();
    this.clearValidation();
    bus().$emit('adminUpdated');
  }
  cancel() {
    this.clearValidation();
    this.initProfileData({...this.userData});
  }

  validateForm(): boolean {
    const form = this.$refs.updateForm as HTMLFormElement;
    form.classList.add('was-validated');
    return form.checkValidity() && !this.isInvalidConfirmation;
  }

  timeZoneSelected(zone: string) {
    if (this.adminProfile) {
      this.adminProfile.timeZone = zone;
    }
  }

  get isInvalidConfirmation() {
    return this.adminProfile.password && this.adminProfile.password !== this.adminProfile.passwordConfirmation;
  }

  async validatePassword(): Promise<boolean> {
    let isValid = true;

    // References for the password fields
    const currentPasswordInput = this.$refs.currentPassword as HTMLInputElement;
    const newPasswordInput = this.$refs.password as HTMLInputElement;
    const confirmPasswordInput = this.$refs.confirmPassword as HTMLInputElement;

    // if all values not set its mean skip password update
    if (!this.currentPassword && !this.adminProfile.password && !this.adminProfile.passwordConfirmation) {
      return true;
    }

    // otherwise continue
    if (!this.currentPassword || this.currentPassword.length < 8) {
      currentPasswordInput.classList.add('is-invalid');
      isValid = false;
    } else {
      currentPasswordInput.classList.remove('is-invalid');
    }

    // Validation for new password
    if (!this.adminProfile.password || this.adminProfile.password.length < 8) {
      newPasswordInput.classList.add('is-invalid');
      isValid = false;
    } else {
      newPasswordInput.classList.remove('is-invalid');
    }

    // Validation for confirm password
    if (
      !this.adminProfile.passwordConfirmation ||
      this.adminProfile.passwordConfirmation !== this.adminProfile.password
    ) {
      confirmPasswordInput.classList.add('is-invalid');
      isValid = false;
    } else {
      confirmPasswordInput.classList.remove('is-invalid');
    }

    // If frontend validations fail, return early
    if (!isValid) {
      return false;
    }

    // Backend validation for current password
    if (this.adminProfile) {
      try {
        const isBackendValid = await stores.SuperAdmins.checkCurrentPassword(this.adminProfile.id, {
          password: this.currentPassword,
          passwordConfirmation: !!this.adminProfile.password
        });

        currentPasswordInput.classList.toggle('is-invalid', !isBackendValid);

        // Clear current password if valid
        if (isBackendValid) {
          this.currentPassword = '';
        }

        return isBackendValid;
      } catch (error) {
        console.error('Error during backend password validation:', error);
        return false;
      }
    }

    return false;
  }

  clearValidation() {
    if (!this.adminProfile) return;
    const form = this.$refs.updateForm as HTMLFormElement;
    form.classList.remove('was-validated');
    (this.$refs.currentPassword as HTMLInputElement).classList.remove('is-invalid');
    this.adminProfile.password = this.adminProfile.passwordConfirmation = '';
  }

  toggleSessionView(event: Event) {
    event.preventDefault();
    this.showAllSessions = !this.showAllSessions;
  }

  get displayedSessions() {
    return this.showAllSessions ? this.adminProfile?.sessions : this.adminProfile?.sessions?.slice(0, 7);
  }

  formatLoginTime(loginTime: Date | null): string {
    if (!loginTime) return 'Never logged in';
    return `${this.formatDate(loginTime, 'dmy')} ${this.formatTime(loginTime, '24')}`;
  }

  getLoginTime(session: ISession): Date | null {
    return session.loginTime ? new Date(session.loginTime) : null;
  }

  getRecentSessionLoginTime(): Date | null {
    return this.displayedSessions.length > 0 ? this.getLoginTime(this.displayedSessions[0]) : null;
  }

  projectSelected(project: any){
    if(project.id === 'all_projects')
    {
      this.selectedProjects = this.allProjects;
      this.selectedProjects = this.selectedProjects.filter((p: any) => p.id !== 'all_projects');
      return;
    }
  }
  toggleAccessToCurrentFutureProjects(): void {
    if (this.adminProfile.futureProjects) {
      this.selectedProjects = this.allProjects.filter((project: any) => project.id !== 'all_projects');
    }
  }
  projectRemoved(project: IProject)
  {
    const selectedProject = this.allProjects.find((p: any) => p.id === project.id);
    if(selectedProject)
      this.selectedProjects.push(selectedProject);
  }
  removeSelectedProject(project: IProject)
  {
    this.selectedProjects = this.selectedProjects.filter((p: any) => p.id !== project.id);
  }
}
