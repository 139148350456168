
import { Vue, Component, Prop } from 'vue-property-decorator';
import stores from '@/stores';
import moment from 'moment';

@Component
export default class ChatMessage extends Vue {
  @Prop({ default: false }) message !: any;

  get name() {
    if(this.message.senderUserType === 'App\\Admin')
    {
        return this.message.senderUser.chatAlias;
    }
    return Vue.prototype.translate(this.message.senderUser.firstName) + ' ' + Vue.prototype.translate(this.message.senderUser.lastName);

  }

  get isMediaMessage() {
    if (this.message.images?.length > 0) {
      return true;
    }

    return false;
  }

  get isDocMessage() {
    if (this.message.files?.length > 0) {
      return true;
    }

    return false;
  }

  get addReplyMessageClass() {
    return this.message.senderUserType === stores.ChatMessageCollection.getAdminType() ? 'card card-right' : 'card card-left';
  }

  get messageTime() {
    return this.message.createdAtReadable;
  }
}
