
import {Component, Prop, Vue} from 'vue-property-decorator';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronFileField from "@/components/NevronFileField.vue";
import {imageExtensions} from "@/helpers/files";

@Component({
  components: {
    AttachImage,
    NevronFileField,
  },
})
export default class NevronImageField extends Vue {

  @Prop({default: null})
  image!: IMediaItem | null;

  @Prop({default: 'image'})
  title!: string;

  @Prop({default: true})
  decoration!: boolean;

  imageExtensions = imageExtensions;

}
