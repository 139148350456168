export const dynamicModules = ['VIDEO', 'SERIES', 'TV_RADIO', 'CATALOGUE', 'SERVICE', 'TV_APPLICATION', 'WEBSITE_APPLICATION', 'MOD', 'AOD'];

export enum ModuleTypePathMap {
  'APPLICATION' = 'applications',
  'TV_APPLICATION' = 'applications',
  'WEBSITE_APPLICATION' = 'applications',
  'SHIP_POSITION_APPLICATION' = 'applications',
  'TV_RADIO' = 'tv',
  'SERIES' = 'series',
  'VIDEO' = 'video',
  'MOD' = 'mod',
  'AOD' = 'aod',
  'CATALOGUE' = 'catalogue',
  'SERVICE' = 'service',
  'TIME' = 'time',
  'WEATHER' = 'weather',
}

export interface IModuleField {
  name?: string;
  displayName?: string;
  type?: string;
  subFields?: string[];
  backupName?: string;
  emptyError?: string;
  notTranslatable?: boolean;
  migrated?: boolean;
  route?: string; // for links
  icons?: object[];
  arrayIndex?: string;
  buttons?: object[];
  popup?: string;
  classes?: string; // optional styling classes
  options?: object[];
  showConfirmModal?: boolean;
}

export interface IModuleMetaData {
  displayName?: string;
  createComponent?: string; // path to the create component
  tableName?: string;
  noActive?: boolean;
  fields?: IModuleField[];
  // For displaying items in a table
  tableSection?: {
    fields?: IModuleField[];
    noActive?: boolean;
  };

  [key: string]: any;
}

export const modulesMetaData: { [key: string]: IModuleMetaData } = {
  messageslog: {
    displayName: 'messagesLog',
    tableName: 'Messages Log',
    noActive: true,
    fields: [
      { displayName: 'message', type: 'object', name: 'message', subFields: ['name']},
      { displayName: 'received',type:'date', name: 'readAt', notTranslatable: true,classes: 'text-small' },
      {
        displayName: 'displayed-on', type: 'icon', name: 'message', subFields: ['displayedOn'], notTranslatable: true,
        icons: [
          {
            name: 'mobile',
            classes: '',
            styles: '',
            path: '../../../img/mobile-b.svg',
          },
          {
            name: 'tv',
            classes: '',
            styles: '',
            path: '../../../img/tv-b.svg',
          }
        ]
      },
      { displayName: 'messaging-type', type: 'type', name: 'message', subFields: ['deliveryChannels'], notTranslatable: true },
      { displayName: 'device-group', type: 'array', name: 'message', subFields: ['deviceGroups'], notTranslatable: true, arrayIndex: 'name' },
    ]
  },
  customers: {
    displayName: 'guests',
    createComponent: 'modules/Customers/Create',
    checkIn: 'modules/Customers/CheckIn',
    tableName: 'customers',
    noActive: true,
    fields: [
      {
        displayName: 'name',
        type: 'dual',
        name: 'firstName',
        backupName: 'lastName',
        emptyError: 'no-name-defined',
      },
      {
        displayName: 'location',
        type: 'object',
        name: 'stays',
        subFields: ['locations'],
        notTranslatable: true,
        migrated: true
      },
      {
        displayName: 'check-in',
        type: 'object',
        name: 'stays',
        subFields: ['checkinAt'],
        notTranslatable: true,

      },
      {
        displayName: 'check-out',
        type: 'object',
        name: 'stays',
        subFields: ['checkoutAt'],
        notTranslatable: true,

      },
      {
        displayName: 'stay',
        type: 'object',
        name: 'stays',
        subFields: ['status'],
        notTranslatable: true,
      },
      {
        displayName: 'status',
        name: 'isStaff',
        notTranslatable: true,

      },
    ],
    modalAttachToUser: {
      displayName: 'link-customer',
      selectOne: true,
    },
    modalAddRecipient: {
      displayName: 'add-recipients',
      selectOne: false,
    },
    modalListRecipient: {
      displayName: 'list-guests',
      selectOne: false,
      optionalParams: '',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: 'name',
          type: 'dual',
          name: 'firstName',
          backupName: 'lastName',
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'location',
          type: 'object',
          name: 'stays',
          subFields: ['locations'],
          emptyError: ' ',
        },
        {
          displayName: 'check-in',
          type: 'object',
          name: 'stays',
          subFields: ['checkinAt'],
          notTranslatable: true,

        },
        {
          displayName: 'check-out',
          type: 'object',
          name: 'stays',
          subFields: ['checkoutAt'],
          notTranslatable: true,

        },
        {
          displayName: 'stay',
          type: 'object',
          name: 'stays',
          subFields: ['status'],
          notTranslatable: true,

        },
        {
          displayName: 'status',
          name: 'isStaff',
          notTranslatable: true,

        },
      ],
    },
    tableSectionStays: {
      fields: [
        {
          displayName: 'name',
          type: 'dual',
          name: 'firstName',
          backupName: 'lastName',
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'main-customer',
          type: 'radio',
          name: 'mainCustomer',
        },
      ],
    },
    tableCreateStay: {
      selectOne: true,
    },
  },
  combinedUser: {
    displayName: 'combined-users',
    createComponent: 'modules/CombinedUser/User/Create',
    fields: [
      {
        displayName: 'first-name',
        name: 'firstName',
        notTranslatable: true,
      },
      {
        displayName: 'last-name',
        name: 'lastName',
        notTranslatable: true,
      },
      {
        displayName: 'gender',
        name: 'gender',
        notTranslatable: true,
      },
      {
        displayName: 'email',
        name: 'email',
        notTranslatable: true,
      },
    ],
    options: {
      hideDelete: true,
      hideImport: true,
      hideExport: true,
    },
  },
  interests: {
    displayName: 'interests',
    createComponent: 'modules/Interests/Create',
    fields: [
      {
        displayName: '',
        type: 'image',
        name: 'image',
      },
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  guestflowmodule: {
    displayName: 'guest-flow-modules',
    tableName: 'guest_flow_modules',
    fields: [
      {
        displayName: '',
        name: 'image',
        type: 'image',
      },
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
    options: {
      hideDelete: true,
    },
  },

  stays: {
    displayName: 'stays',
    createComponent: 'modules/Stays/Create',
    tableName: 'stays',
    noActive: true,
    fields: [
      {
        displayName: 'main-customer',
        type: 'object',
        name: 'mainCustomer',
        subFields: ['firstName', 'lastName'],
        emptyError: 'main-customer-not-set',
      },
      {
        displayName: 'number-of-customers',
        notTranslatable: true,
        name: 'customersCount',
      },
      {
        displayName: 'check-in-date',
        type: 'date',
        name: 'checkinAt',
        emptyError: 'no-date',
      },
      {
        displayName: 'check-out-date',
        type: 'date',
        name: 'checkoutAt',
        emptyError: 'no-date',
      },
      {
        displayName: 'status',
        name: 'status',
      },
    ],
    // table meta for show pages
    tableSection: {
      noActive: true,
      fields: [
        {
          displayName: 'main-customer',
          type: 'object',
          name: 'mainCustomer',
          subFields: ['firstName', 'lastName'],
          emptyError: 'main-customer-not-set',
        },
        {
          displayName: 'number-of-customers',
          notTranslatable: true,
          name: 'customersCount',
        },
        {
          displayName: 'check-in-date',
          type: 'date',
          name: 'checkinAt',
          emptyError: 'no-date',
        },
        {
          displayName: 'check-out-date',
          type: 'date',
          name: 'checkoutAt',
          emptyError: 'no-date',
        },
        {
          displayName: 'status',
          name: 'status',
        },
      ],
    },
    tableSectionLocation: {
      noActive: true,
      fields: [
        {
          displayName: 'main-customer-name',
          type: 'object',
          name: 'mainCustomer',
          subFields: ['firstName', 'lastName'],
          emptyError: 'main-customer-not-set',
        },
        {
          displayName: 'related-guests',
          notTranslatable: true,
          name: 'customersCount',
        },
        {
          displayName: 'check-in-date',
          type: 'date',
          name: 'checkinAt',
          emptyError: 'no-date',
        },
        {
          displayName: 'check-out-date',
          type: 'date',
          name: 'checkoutAt',
          emptyError: 'no-date',
        },
        {
          displayName: 'guest-id',
          name: 'mainCustomer',
          subFields: ['id'],
          emptyError: 'main-customer-not-set',
          notTranslatable: true
        },
      ],
    },
  },

  stayTypes: {
    displayName: 'stay-types',
    createComponent: 'modules/Stays/Types/Create',
    tableName: 'stay_categories',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  promotions: {
    displayName: 'promotions',
    createComponent: 'modules/Promotions/Create',
    tableName: 'promotions',
    fields: [
      {
        displayName: '',
        name: 'image',
        type: 'image',
        subFields: ['image'],
      },
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
      {
        displayName: 'valid-to',
        name: 'validTo',
        emptyError: 'no-date-defined',
        notTranslatable: true,
      },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: '',
          name: 'image',
          type: 'image',
          subFields: ['image'],
        },
        {
          displayName: 'name',
          name: 'name',
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'valid-to',
          name: 'validTo',
          emptyError: 'no-date-defined',
          notTranslatable: true,
        },
      ],
    },

  },

  manageProperty: {
    displayName: 'manage-property',
  },
  assets: {
    displayName: 'assets',
    createComponent: 'modules/PropertyAssets/Assets/Create',
    tableName: 'property_assets',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      ],
    },
  },
  assetCategory: {
    displayName: 'asset-categories',
    createComponent: 'modules/PropertyAssets/Assets/Category/Create',
    tableName: 'asset_categories',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  contactModule: {
    displayName: 'contacts',
    createComponent: 'modules/ContactModule/Create',
    tableName: 'contacts',
    fields: [
      {
        displayName: 'contact-name',
        name: 'name',
        emptyError: 'No Name Defined',
      },
      {
        displayName: 'contact-number',
        name: 'number',
        emptyError: 'No Name Defined',
        notTranslatable: true,
      },
    ],
  },
  location: {
    displayName: 'locations',
  },
  zones: {
    displayName: 'locations',
    createComponent: 'modules/PropertyAssets/Locations/Zones/Create',
    tableName: 'locations',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
        notTranslatable: true,
        migrated: true
      },
    ],
    modalAttachToDevice: {
      displayName: 'link-location',
      selectOne: true,
    },
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined', notTranslatable: true, migrated: true},
      ],
    },
  },

  zoneCategory: {
    displayName: 'room-type',
    createComponent: 'modules/PropertyAssets/Locations/Category/Create',
    tableName: 'location_categories',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  library: {
    displayName: 'documents',
    createComponent: 'modules/PropertyAssets/Library/Create',
    tableName: 'accommodation_libraries',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      ],
    },
  },

  ratingPlatform: {
    displayName: 'rating-platforms',
    createComponent: 'modules/PropertyAssets/Media/RatingPlateform/Create',
    tableName: 'rating_platforms',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  commentProposal: {
    displayName: 'social-media-templates',
    createComponent: 'modules/PropertyAssets/Media/CommentProposals/Create',
    tableName: 'comment_proposals',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  socialMedia: {
    displayName: 'social-media-platforms',
    createComponent: 'modules/PropertyAssets/Media/SocialMedia/Create',
    tableName: 'social_media_platforms',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
    ],
  },

  stbDashboards: {
    displayName: 'stb-dashboards',
    createComponent: 'modules/Dashboards/Create',
    tableName: 'dashboards',
    fields: [
      { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      { displayName: 'default', name: 'default', type: 'radio' },
      { displayName: 'public', name: 'public', type: 'radio' },
    ],
    modalAttach: {
      selectOne: true,
      displayName: 'link-stb-dashboard',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      ],
    },
    options: {
      showDuplicate: true,
    },
  },

  mobileDashboards: {
    displayName: 'mobile-dashboards',
    createComponent: 'modules/Dashboards/Create',
    tableName: 'dashboards',
    fields: [
      { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      { displayName: 'default', name: 'default', type: 'radio' },
      { displayName: 'public', name: 'public', type: 'radio' },
    ],
    modalAttach: {
      selectOne: true,
      displayName: 'link-mobile-dashboard',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      ],
    },
    options: {
      showDuplicate: true,
    },
  },
  gfMobileDashboard: {
    displayName: 'gf-mobile-dashboards',
    tableName: 'dashboards',
    fields: [
      { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      { displayName: 'default', name: 'default', type: 'radio' },
      { displayName: 'public', name: 'public', type: 'radio' },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      ],
    },
  },

  modules: {
    displayName: 'modules',
    createComponent: 'modules/Modules/Create',
    tableName: 'modules',
    fields: [
      { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      { displayName: 'type', name: 'type', notTranslatable: true },
      { displayName: 'id', name: 'id', notTranslatable: true },
    ],
    attachModulesTable: {
      displayName: 'link-catalog-modules',
      optionalParams: 'type=CATALOGUE',
      fields: [
        { displayName: 'name', type: 'nevron-input', name: 'name' },

      ],
    },
    attachModalService: {
      displayName: 'link-service-modules',
      optionalParams: 'type=SERVICE',
    },
  },

  accounts: {
    displayName: 'accounts',
    createComponent: 'modules/Accounts/Create',
    tableName: 'accounts',
    fields: [
      {
        displayName: 'name',
        name: 'name',
        emptyError: 'no-name-defined',
      },
      { displayName: 'default', name: 'default', type: 'radio' },
    ],
    modalAttach: {
      displayName: 'link-accounts',
      selectOne: false, // when only one instance is needed (radio instead of checkbox)
      fields: [
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
        {
          displayName: 'customer',
          type: 'object',
          name: 'customer',
          subFields: ['firstName', 'lastName'],
          emptyError: 'no-customer-defined',
          route: 'customers.show',
        },
      ],
    },
    modalAttachToDevice: {
      displayName: 'link-accounts',
      selectOne: true,
    },
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      ],
    },
  },

  users: {
    displayName: 'users',
    createComponent: 'modules/Users/Create',
    tableName: 'users',
    fields: [
      {
        displayName: 'username',
        name: 'username',
        notTranslatable: true,
        emptyError: 'no-name-defined',
      },
      {
        displayName: 'customer',
        type: 'object',
        name: 'customer',
        subFields: ['firstName', 'lastName'],
        emptyError: 'no-customer-defined',
        route: 'customers.show',
      },
      {
        displayName: 'email',
        name: 'email',
        notTranslatable: true,
        emptyError: 'no-email-defined',
      },
    ],
    modalAttach: {
      displayName: 'link-users',
      selectOne: false, // when only one instance is needed (radio instead of checkbox)
      fields: [
        {
          displayName: 'name',
          name: 'username',
          emptyError: 'No Name Defined',
          notTranslatable: true,
        },
        {
          displayName: 'customer',
          type: 'object',
          name: 'customer',
          subFields: ['firstName', 'lastName'],
          emptyError: 'no-customer-defined',
          route: 'customers.show',
        },
      ],
    },
    modalAttachToCustomer: {
      displayName: 'link-users',
      selectOne: true,
    },

    tableSection: {
      fields: [
        {
          displayName: 'username',
          name: 'username',
          notTranslatable: true,
          emptyError: 'no-name-defined',
        },
        {
          displayName: 'email',
          name: 'email',
          notTranslatable: true,
          emptyError: 'no-email-defined',
        },
      ],
    },
  },

  devices: {
    displayName: 'devices',
  },
  device: {
    displayName: 'device',
    createComponent: 'modules/Devices/Create',
    tableName: 'devices',
    fields: [
      {
        displayName: 'serial-number',
        name: 'serialNumber',
        emptyError: 'no-serial-number-defined',
        notTranslatable: true,
      },
      {
        displayName: 'mac-address',
        name: 'macEth',
        backupName: 'macWifi',
        emptyError: 'no-mac-address-defined',
        notTranslatable: true,
      },
      {
        displayName: 'location',
        name: 'location',
        subFields: ['name'],
        route: 'zones.show',
        emptyError: 'no-location-defined',
        notTranslatable: true,
        migrated: true,
      },
      {
        displayName: 'type',
        type: 'object',
        name: 'deviceType',
        subFields: ['default'],
        notTranslatable: true
      },
      {
        displayName: 'firmware-version',
        name: 'firmware',
        emptyError: 'no-firmware-defined',
        notTranslatable: true,
      },
      {
        displayName: 'apk-version',
        name: 'apk',
        emptyError: 'no-apk-defined',
        notTranslatable: true,
      },
      {
        displayName: 'action-status',
        name: 'deviceActionStatus',
        emptyError: 'no-action-status-defined',
        notTranslatable: false,
      },
      {
        displayName: 'device-connection-code',
        name: 'deviceConnectionCode',
        emptyError: 'no-device-connection-code-defined',
        notTranslatable: true,
      },
    ],
    modalAttach: {
      displayName: 'link-device',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        {
          displayName: 'mac-address',
          name: 'macEth',
          backupName: 'macWifi',
          emptyError: 'no-mac-address-defined',
          notTranslatable: true,
        },
        {
          displayName: 'name',
          name: 'name',
          emptyError: 'no-name-defined'
        },
        {
          displayName: 'type',
          type: 'object',
          name: 'deviceType',
          subFields: ['name'],
        },
      ],
    },
    accountDeviceTableSection: {
      fields: [
        {
          displayName: 'mac-address-short',
          name: 'macEth',
          backupName: 'macWifi',
          emptyError: 'no-mac-address-defined',
          notTranslatable: true,
        },
        {
          displayName: 'location',
          subFields: ['name'],
          name: 'location',
          emptyError: 'no-location-defined',
        },
        {
          displayName: 'ip',
          name: 'ip',
          emptyError: 'no-ip-defined',
          notTranslatable: true,
        },
        {
          displayName: 'type',
          type: 'object',
          name: 'deviceType',
          subFields: ['name'],
        },
      ],
    },
    roomDeviceTableSection: {
      fields: [
        {
          displayName: 'name',
          name: 'name',
          emptyError: 'no-name-defined'
        },
        {
          displayName: 'mac-address-short',
          name: 'macEth',
          backupName: 'macWifi',
          emptyError: 'no-mac-address-defined',
          notTranslatable: true,
        },
        {
          displayName: 'ip',
          name: 'ip',
          emptyError: 'no-ip-defined',
          notTranslatable: true,
        },
        {
          displayName: 'type',
          type: 'object',
          name: 'deviceType',
          subFields: ['name'],
        },
      ],
    },
    options: {
      hideImport: false,
      hideExport: false,
    },
  },
  deviceGroup: {
    displayName: 'device-group',
    createComponent: 'modules/Devices/Group/Create',
    tableName: 'device-groups',
    fields: [
      { displayName: 'name', name: 'name' },
      { displayName: 'type', name: 'type' },
    ],
    modalAttach: {
      displayName: 'link-device',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name' },
        { displayName: 'default', name: 'default' },
      ],
    },
  },
  deviceType: {
    displayName: 'device-type',
    createComponent: 'modules/Devices/Types/Create',
    tableName: 'device_types',
    fields: [
      { displayName: 'name', name: 'name' },
      { displayName: 'default', name: 'default' },

    ],
    modalAttach: {
      displayName: 'link-device',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name' },
        { displayName: 'default', name: 'default' },
      ],
    },
  },

  packages: {
    displayName: 'packages',
    createComponent: 'modules/Packages/Create',
    tableName: 'packages',
    fields: [
      { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      { displayName: 'default', name: 'default', type: 'radio' },
      { displayName: 'type', name: 'type', emptyError: '' },
    ],
    modalAttach: {
      displayName: 'link-package-items',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      ],
    },
  },
  messaging: {
    displayName: 'messaging-dashboard',
    createComponent: 'modules/Messaging/Create',
    showComponent: 'modules/Messaging/Create',
    tableName: 'messages',
    noActive: false,
    fields: [
      { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
      { displayName: 'status', type: 'diff', name: 'status', notTranslatable: true },
      { displayName: 'scheduled-date', type: 'date', name: 'scheduledAt', notTranslatable: true },
      { displayName: 'displayed-on', name: 'displayedOn', type: 'object', subFields: [] },
    ],
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined' },
        { displayName: 'status', type: 'diff', name: 'status', notTranslatable: true },
        { displayName: 'scheduled-date', type: 'date', name: 'scheduledAt', notTranslatable: true },
        { displayName: 'displayed-on', name: 'displayedOn', type: 'object', subFields: [] },
      ],
    },
    linkModuleEdit: {
      displayName: 'edit-linked-module',
      fields: [
        { displayName: 'active', type: 'checkbox', name: 'active' },
      ],
    },
  },

  digitalSignage: {
    createComponent: 'modules/DigitalSignage/CreatePlaylist',
    showComponent: 'modules/DigitalSignage/CreatePlaylist',
    displayName: 'digital-signage',
    tableName: 'digital_signage_playlists',
    fields: [
      {
        displayName: 'name',
        name: 'title',
        notTranslatable: true,
      },
      {
        displayName: 'from',
        name: 'onDisplayStart',
        notTranslatable: true,
      },
      {
        displayName: 'to',
        name: 'onDisplayEnd',
        notTranslatable: true,
      },
      {
        displayName: 'status',
        name: 'status',
        notTranslatable: true,
      },
    ],
    options: {
      showDuplicate: true,
    },
  },

  surveys: {
    createComponent: 'modules/Surveys/Create',
    showComponent: 'modules/Surveys/Create',
    displayName: 'surveys',
    tableName: 'surveys',
    noActive: false,
    tooltip: true,
    tooltipColumn: 'validity',
    fields: [
      {displayName: 'survey-name', name: 'name', emptyError: 'no-name-defined'},
      {displayName: 'delivery', name: 'displayed', notTranslatable: true, emptyError: ''},
      {displayName: 'status', type: 'icons', name: 'status', notTranslatable: true,
      icons : [
        {
          name: 'scheduled',
          prependText : false,
          appendText : true,
          tooltip: true,
          tooltipColumn: 'displayed',
          classes: '',
          styles: '',
          path: '../../../img/scheduled-b.svg',
        }
      ]
     },
      {displayName: 'displayed-on', type: 'icons', name: 'displayed-on' , icons : [
        {
          name: 'mobile',
          classes: '',
          styles: '',
          path: '../../../img/mobile-b.svg',
        },
        {
          name: 'tv',
          classes: '',
          styles: '',
          path: '../../../img/tv-b.svg',
      }
      ]},
       {displayName: 'type', type: 'icons', name: 'type', icons : [
        {
            name: 'emoticons',
            classes: '',
            styles: '',
            path: '../../../img/my_mood-b.svg',
        },
        {
          name: 'open_ended',
          classes: '',
          styles: '',
          path: '../../../img/survey-b.svg',
        }
      ]},
      {displayName: 'displayed-to', type: 'diff', name: 'recipientType', notTranslatable: true},
      {displayName: '', type: 'buttons', name: '', buttons : [
        {
            name: 'list',
            text: 'list',
            classes: 'btn btn-primary badge-pill px-5 mr-5',
            styles: '',
            clickEvent: 'openList'
        },
        {
          name: 'results',
          text: 'results',
          classes: 'btn btn-primary badge-pill px-5 mr-5',
          styles: '',
          clickEvent: 'openResult'
        }
      ]},
    ],
    options: {
      showDuplicate: true,
      hideImport: true
    },
  },
  moods: {
    displayName: 'moods',
    tableName: 'moods',
    noActive: true,
    fields: [
      {
        displayName: 'my-mood-id', name: 'id', type: 'icons', notTranslatable: true, emptyError: 'no-name-defined', icons: [
          {
            name: 'id',
            classes: '',
            styles: 'width:12px',
            prependText: false,
            appendText: true,
            path: require(`@/assets/emoticons/warning_urgent.svg`),
          }
        ]
      },
      { displayName: 'guest', type: 'object', name: 'customer', subFields: ['firstName', 'lastName'] },
      { displayName: 'date', name: 'createdAt', type: 'date', notTranslatable: true, emptyError: '' },
      {
        displayName: 'mood', name: 'mood', type: 'icons', notTranslatable: true, emptyError: '', icons: [
          {
            name: 'emoji_happy',
            classes: '',
            styles: 'width:32px',
            path: require(`@/assets/emoticons/emoji_happy.svg`),
          },
          {
            name: 'emoji_content',
            classes: '',
            styles: 'width:32px',
            path: require(`@/assets/emoticons/emoji_content.svg`),
          },
          {
            name: 'emoji_unhappy',
            classes: '',
            styles: 'width:32px',
            path: require(`@/assets/emoticons/emoji_unhappy.svg`),
          },
          {
            name: 'emoji_sad',
            classes: '',
            styles: 'width:32px',
            path: require(`@/assets/emoticons/emoji_sad.svg`),
          }


        ]
      },
      { displayName: 'response', name: 'response', notTranslatable: true, emptyError: '' },
      { displayName: 'message-label', name: 'message', notTranslatable: true, emptyError: '' },
      {
        displayName: '', type: 'buttons', name: 'createdAt', notTranslatable: true, emptyError: '', buttons: [
          {
            name: 'list',
            text: 'details',
            classes: 'btn btn-primary badge-pill px-5 mr-5',
            styles: '',
          }
        ]
      },

    ],
    options: {
      hideImport: true,
      hideExport: true,
      hideDelete: true,
      hideEdit: true,
    },
    moodsHistory: {
      noActive: true,
      fields: [
        {displayName: 'date-and-time', name: 'created_at', type:'dateAndTime', notTranslatable: true, emptyError: ''},
        {displayName: 'mood', name: 'mood', type: 'icons', notTranslatable: true, emptyError: '',  icons : [
          {
              name: 'emoji_happy',
              classes: '',
              styles: 'width:32px',
              path: require(`@/assets/emoticons/emoji_happy.svg`),
          },
          {
            name: 'emoji_content',
            classes: '',
            styles: 'width:32px',
            path: require(`@/assets/emoticons/emoji_content.svg`),
          },
          {
            name: 'emoji_unhappy',
            classes: '',
            styles: 'width:32px',
            path: require(`@/assets/emoticons/emoji_unhappy.svg`),
          },
          {
          name: 'emoji_sad',
          classes: '',
          styles: 'width:32px',
          path: require(`@/assets/emoticons/emoji_sad.svg`),
        }

        ]},
        {displayName: '', type: 'buttons', name: 'createdAt', notTranslatable: true, emptyError: '', buttons : [
          {
              name: 'list',
              text: 'details',
              classes: 'btn btn-outline-light',
              styles: '',
          }
        ]},

      ],
    },
  },



  welcomeScreen: {
    createComponent: 'modules/WelcomeScreen/Create',
    showComponent: 'modules/WelcomeScreen/Create',
    displayName: 'welcomeScreen',
    tableName: 'welcome_screen',
    noActive: false,
    fields: [
      { displayName: 'name', name: 'name', emptyError: 'no-name-defined', notTranslatable: true },
      { displayName: 'status', type: 'diff', name: 'status', notTranslatable: true },
      { displayName: 'default', name: 'default', type: 'radio' },
    ],
  },

  languages: {
    displayName: 'languages',
    createComponent: 'modules/Languages/Create',
    tableName: 'languages',
    type: 'language',
    fields: [
      { displayName: 'flag', type: 'image' },
      { displayName: 'name', name: 'name', emptyError: 'no-name-defined', notTranslatable: true },
      { displayName: 'default', name: 'default', type: 'radio' },
      { displayName: 'locale-code', name: 'localCode', notTranslatable: true },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
    modalAttach: {
      displayName: 'link-languages',
    },
    // table meta for show pages
    tableSection: {
      fields: [
        { displayName: 'flag', type: 'image' },
        { displayName: 'name', name: 'name', emptyError: 'no-name-defined', notTranslatable: true },
        { displayName: 'locale-code', name: 'localCode', notTranslatable: true },
        { displayName: 'sort', name: 'sort', type: 'sort' },
      ],
    },
  },

  translation: {
    displayName: 'translations',
    createComponent: 'modules/Translation/AddKey',
    tableName: 'translations',
    fields: [
      { displayName: 'key', name: 'key', emptyError: 'no-key-defined', notTranslatable: true },
      { displayName: 'value', name: 'key', emptyError: 'no-value-defined' },
    ],
    noActive: true,
  },

  clock: {
    createComponent: 'modules/Time/Create',
    showComponent: 'modules/Time/Edit',
    tableName: 'world_clock_locations',
    fields: [
      { displayName: 'city', name: 'city' },
      { displayName: 'timezone', name: 'timeZone', notTranslatable: true },
    ],
  },

  locations: {
    createComponent: 'modules/Weather/Create',
    showComponent: 'modules/Weather/Edit',
    tableName: 'weather_locations',
    fields: [
      { displayName: 'city', name: 'city' },
      { displayName: 'country', name: 'country', notTranslatable: true },
      { displayName: 'latitude', name: 'latitude', emptyError: 'N/A', notTranslatable: true },
      { displayName: 'longitude', name: 'longitude', emptyError: 'N/A', notTranslatable: true },
      { displayName: 'default', name: 'defaultCity', type: 'radio' }
    ],
  },

  tv: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'tv_channels',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'channel-number', name: 'channelNumber', type: 'sort' },
    ],
  },

  video: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'video_items',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
  },

  series: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'series',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
    crewEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            { displayName: 'director', name: 'DIRECTOR' },
            { displayName: 'writer', name: 'WRITER' },
          ],
        },
      ],
    },
    castEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            { displayName: 'actor', name: 'ACTOR' },
          ],
        },
      ],
    },
  },
  mod: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'series',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
    crewEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            { displayName: 'director', name: 'DIRECTOR' },
            { displayName: 'writer', name: 'WRITER' },
          ],
        },
      ],
    },
    castEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            { displayName: 'actor', name: 'ACTOR' },
          ],
        },
      ],
    },
  },
  aod: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'aod_items',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
    crewEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            { displayName: 'director', name: 'DIRECTOR' },
            { displayName: 'writer', name: 'WRITER' },
          ],
        },
      ],
    },
    castEdit: {
      displayName: 'edit-position',
      fields: [
        {
          displayName: 'position', type: 'radio', name: 'role', subFields: [
            { displayName: 'actor', name: 'ACTOR' },
          ],
        },
      ],
    },
  },

  applications: {
    dynamicModule: true,
    tableName: 'application_items',
    createComponent: 'modules/Application/Items/Create',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
  },

  tv_applications: {
    dynamicModule: true,
    tableName: 'application_items',
    createComponent: 'modules/Application/Items/Create',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
  },

  website_applications: {
    dynamicModule: true,
    tableName: 'application_items',
    createComponent: 'modules/Application/Items/Create',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
  },

  ship_position_applications: {
    dynamicModule: true,
    tableName: 'application_items',
    createComponent: 'modules/Application/Items/Create',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
  },

  catalogue: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'catalogue_items',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
    categories: {
      fields: [
        { displayName: '', type: 'image' },
        { displayName: 'name', name: 'name' },
        {
          displayName: 'sort',
          type: 'pivot',
          pivotKey: 'pivot',
          name: 'sort',
          backupFieldName : 'sort',
          wrapperElement : 'div',
          wrapperElementClasses : 'rounded text-center border',
          wrapperElementStyles : 'width: 35px',
        }
      ],
    },
    items: {
      draggable : true,
      fields: [
        {displayName: '', type: 'image'},
        {displayName: 'name', name: 'name'},
        {
          displayName: 'sort',
          type: 'pivot',
          pivotKey: 'pivot',
          name: 'sort',
          backupFieldName : 'sort',
          wrapperElement : 'div',
          wrapperElementClasses : 'rounded text-center border',
          wrapperElementStyles : 'width: 35px',
        }
      ],
    },
    linkModuleEdit: {
      displayName: 'edit-linked-module',
      fields: [
         { displayName: 'link-action-label', type: 'nevron-input', name: 'action_label' },
         { displayName: 'sort', type: 'number', name: 'sort' },
         { displayName: 'active', type: 'checkbox', name: 'active' },
      ],
    },
    linkModuleTable: {
      displayName: 'linked-module-table',
      noActive:true,
      fields: [
        { displayName: 'name', type: 'nevron-input', name: 'name' },
        {
          displayName: 'link-action-label',
          type: 'pivot',
          pivotKey: 'pivot',
          name: 'actionLabel',
          notTranslatable: false,
          backupFieldName : 'name',
          wrapperElement : 'div',
          wrapperElementClasses : 'rounded text-left',
        },
        {
          displayName: 'sort',
          type: 'pivot',
          pivotKey: 'pivot',
          name: 'sort',
          backupFieldName : 'sort',
          wrapperElement : 'div',
          wrapperElementClasses : 'rounded text-center border',
          wrapperElementStyles : 'width: 35px',
        },
        {
          displayName: 'active',
          type: 'pivot',
          pivotKey: 'pivot',
          name: 'active',
          backupFieldName : 'name',
          isSwitchField: true,
          disabled:true,
          switchTheme:'info',
          switchActiveTheme:'primary',
          switchInActiveTheme:'danger',
          wrapperElement : 'div',
          wrapperElementClasses : '',
          wrapperElementStyles : 'position:relative;top:15px',
        },
      ],
    },
  },
  service: {
    dynamicModule: true,
    createComponent: 'components/ListNevronComponents/Items/CreateDynamicModules',
    tableName: 'catalogue_items',
    fields: [
      { displayName: '', type: 'image' },
      { displayName: 'name', name: 'name' },
      { displayName: 'sort', name: 'sort', type: 'sort' },
    ],
    categories: {
      fields: [
        { displayName: '', type: 'image' },
        { displayName: 'name', name: 'name' },
        {
          displayName: 'sort',
          type: 'object',
          name: 'pivot',
          subFields: ['sort'],
        },
      ],
    },
    linkModuleEdit: {
      displayName: 'edit-linked-module',
      fields: [
        { displayName: 'name', type: 'nevron-input', name: 'name' },
        { displayName: 'link-action-label', type: 'nevron-input', name: 'action_label' },
        { displayName: 'sort', type: 'number', name: 'sort' },
        { displayName: 'active', type: 'checkbox', name: 'active' },
      ],
    },
    options: {
      hideImport: true,
      hideExport: true,
      hideDelete: false,
      hideCopy: true,
      hideMove: true,
    },
  },

  accessories: {
    displayName: 'accessory',
    createComponent: 'modules/Accessories/Create',
    fields: [
      {
        displayName: 'name',
        type: 'nevron-input',
        name: 'name',
        emptyError: 'No Name Defined',
      },
      {
        displayName: 'short-description',
        type: 'nevron-input',
        name: 'shortDescription',
        emptyError: 'No Short Description Defined',
      },
      {
        displayName: 'categories',
        type: 'multi-select',
        name: 'accessoryCategories',
      },
      {
        displayName: 'price',
        type: 'number',
        name: 'price',
      },
      {
        displayName: 'active',
        type: 'checkbox',
        name: 'active',
      },
      {
        displayName: 'quantity-selection',
        type: 'checkbox',
        name: 'countable',
      },
      {
        displayName: 'online-payments',
        type: 'checkbox',
        name: 'onlinePayments',
      },
      {
        displayName: 'comments-section',
        type: 'checkbox',
        name: 'commentsSection',
      },
      {
        displayName: 'date',
        type: 'date',
        name: 'accessoryDate',
      },
      {
        displayName: 'time',
        type: 'time',
        name: 'accessoryTime',
      },
      {
        displayName: 'date-and-time',
        type: 'customDateAndTime',
        name: 'datesAndTimes',
      },
    ],
    attachAccessories: {
      optionalParams: 'moduleId',
      displayName: 'link-accessories',
      fields: [
        {
          displayName: 'name',
          type: 'nevron-input',
          name: 'name',
          emptyError: 'No Name Defined',
        },
        {
          displayName: 'short-description',
          type: 'nevron-input',
          name: 'shortDescription',
          emptyError: 'No Short Description Defined',
        },
      ],
    },
  },

  orders: {
    displayName: 'orders',
    noActive: true,
    queryTabParam: true,
    showComponent: 'modules/Orders/Edit',
    fields: [
      { displayName: 'order-id', name: 'id', notTranslatable: true },
      { displayName: 'ordered', name: 'orderAt', notTranslatable: true },
      { displayName: 'guest', type: 'object', name: 'customer', subFields: ['firstName', 'lastName'] },
      { displayName: 'order-type', name: 'orderType', notTranslatable: true },
      { displayName: 'items', name: 'noOfItems', notTranslatable: true },
      { displayName: 'catalogue', name: 'modules', type: 'object', subFields: ['name'], notTranslatable: false },
      { displayName: 'total', name: 'amount', notTranslatable: true },
      { displayName: 'status', name: 'status', notTranslatable: true },
      { displayName: 'location', name: 'roomId', notTranslatable: true },
    ],
    options: {
      hideImport: false,
      hideExport: false,
    },
  },

  reservations: {
    displayName: 'reservations',
    noActive: true,
    showComponent: 'modules/Orders/Edit',
    fields: [
      { displayName: 'reservation-id', name: 'id', notTranslatable: true },
      { displayName: 'reserved', name: 'orderAt', notTranslatable: true },
      { displayName: 'guest', type: 'object', name: 'customer', subFields: ['firstName', 'lastName'] },
      { displayName: 'catalogue', name: 'modules', type: 'object', subFields: ['name'], notTranslatable: false },
      { displayName: 'total', name: 'amount', notTranslatable: true, type: 'number' },
      { displayName: 'status', name: 'status', notTranslatable: true },
      { displayName: 'location', name: 'roomId', notTranslatable: true },
    ],
    options: {
      hideImport: true,
      hideExport: true,
    },
  },
  roomcare: {
    displayName: 'room-care',
    noActive: true,
    showDynamicModule: false,
    showComponent: 'modules/Orders/Edit',
    fields: [
      { displayName: 'reservation-id', name: 'id', notTranslatable: true },
      { displayName: 'reserved', name: 'orderAt', notTranslatable: true },
      { displayName: 'guest', type: 'object', name: 'customer', subFields: ['firstName', 'lastName'] },
      { displayName: 'catalogue', name: 'modules', type: 'object', subFields: ['name'], notTranslatable: false },
      { displayName: 'total', name: 'amount', notTranslatable: true, type: 'number' },
      { displayName: 'status', name: 'status', notTranslatable: true },
      { displayName: 'location', name: 'roomId', notTranslatable: true },
    ],
    options: {
      hideImport: true,
      hideExport: true,
      hideDelete: true,
    },
  },
  skipcleaning: {
    displayName: 'skip-cleaning-requests',
    tableName: 'skip-cleaning-requests',
    queryTabParam: true,
    noActive: true,
    showComponent: 'modules/SkipCleaning/Edit',
    fields: [
      { displayName: 'guest', type: 'object', name: 'customer', subFields: ['firstName', 'lastName'] },
      {
        displayName: 'skip-on',
        type: 'date',
        name: 'date',
        emptyError: 'no-date',
      },
      { displayName: 'room', name: 'location',type:'object', subFields:['pmsRoomId'], notTranslatable: true },
      {
        displayName: 'status', type: 'dropdown', classes: 'text-center p-2', name: 'status',showConfirmModal: true, options: [
          {
            value: 'pending',
            name: 'Pending',
            classes: 'bg-orange text-color-orange font-weight-bold px-1 py-1',
            styles: '',
          },
          {
            value: 'canceled',
            name: 'Canceled',
            classes: 'bg-grey text-color-grey font-weight-bold px-1 py-1',
            styles: '',
          },
          {
            value: 'confirmed',
            name: 'Confirmed',
            classes: 'bg-yellow text-color-yellow font-weight-bold px-1 py-1',
            styles: '',
          },
        ]
      },
      {
        displayName: 'view-details', type: 'buttons', classes: 'text-center', name: '', buttons: [
          {
            name: 'view-details',
            text: 'view-details',
            classes: 'btn btn-primary badge-pill px-3',
            styles: '',
            clickEvent: 'openItem',
          },
        ]
      },
    ],
    options: {
      hideImport: true,
      hideExport: true,
      hideDelete: true,
      hideActions: true,
      showFirstColumnAsLink: true,
    },
  },
  rss: {
    displayName: 'rss',
    createComponent: 'modules/RSS/Create',
    showComponent: 'modules/RSS/Create',
    tableName: 'rss_feeds',
    noActive: true,
    fields: [
      { displayName: 'thumbnail', type: 'image' },
      {displayName: 'name', name: 'name'},
    ],
    options: {
      hideImport: true,
      hideExport: true,
    },
  },
};
