import Axios from 'axios';
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';

const CancelToken: any = Axios.CancelToken;
let cancel: any;
const URL: string = '/devices';

export class Devices extends Model<IDevice> implements IDevice {

  id!: number | null;
  type!: string;
  serialNumber!: string;
  ip!: string;
  macEth!: string;
  macWifi!: string;
  rs232!: string;
  firmware!: string;
  apk!: string;
  manufacturer!: string;
  model!: string;
  switchMac!: string;
  switchIp!: string;
  timeZone!: string;
  name!: string;
  locationId!: number | null;
  deviceTypeId!: number | null;
  deviceActionStatus: DeviceActionStatus = '';
  deviceConnectionCode!: string;
  cacheEnabled!: boolean;
  cacheSize!: number;
  hotspotSsid!: string;
  hotspotPassword!: string;
  castingEnabled!: boolean;

  constructor(attributes: any = {}, parentCollection?: any) {
    super(
      {
        ...{
          id: 0,
          type: '',
          serialNumber: '',
          ip: '',
          mac: '',
          firmware: '',
          apk: '',
          manufacturer: '',
          model: '',
          switchMac: '',
          switchIp: '',
          locationId: null,
          name: '',
          timeZone: '',
          deviceActionStatus: '',
          deviceConnectionCode: '',
        }, ...attributes,
      }, // Default values
      parentCollection,
    );
  }

  urlRoot(): string {
    return URL;
  }
}

export default class DevicesCollection extends Collection<Devices> {

  routeName = 'device'; // same as routers base name

  model(): Constructor<Devices> {
    return Devices;
  }

  url(): string {
    return URL; // nared še eno metodo za type
  }

  updateDeviceType(id: any, data: any) {
    return axiosInstance.put(`${this.url()}/types/${id}`, data)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateItem(id: any, data: any) {
    return axiosInstance.patch(`${this.url()}/${id}`, data)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e.response.data;
      });
  }

  fetchDeviceTypesData(index: number | 1, search: string | null, perpage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }
    if (index === null) {
      index = 1;
    }
    if (search) {
      return axiosInstance.get(`${this.url()}/types?search=${search}&page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.url()}/types?page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }

  fetchData(index: number | null, search: string | null, perpage: number | 10) {
    if (cancel !== undefined) {
      cancel();
    }

    if (index === null) {
      index = 1;
    }

    if (search) {
      return axiosInstance.get(`${this.url()}?search=${search}&page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.url()}?page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: this.routeName,
            message: e,
          };
          generateLog(log);
        });
    }
  }

  detachAccount(deviceID: number) {
    return axiosInstance.patch(`${this.url()}/${deviceID}/account/detach`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  createNewDevice(device: IDevice) {
    return axiosInstance.post(`${this.url()}`, device)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return e.response.data;
      });
  }

  getAllDevices() {
    return axiosInstance.get(`${this.url()}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getDevicesOnPage(index: number) {
    return axiosInstance.get(`${this.url()}?page=${index}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  saveDeviceEdit(device: any) {
    return axiosInstance.put(`${this.url()}/${device.id}`, device)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getSelectedDevice(id: number) {
    return axiosInstance.get(`${this.url()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getAccountForDevice(id: number) {
    return axiosInstance.get(`${this.url()}/${id}/account`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getLocationForDevice(id: number) {
    return axiosInstance.get(`${this.url()}/${id}/location`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getAllDeviceTypes() {
    return axiosInstance.get(`${this.url()}/types`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getAllDeviceTypesFull() {
    return axiosInstance.get(`${this.url()}/types/all`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
  getAllDeviceGroup(device_id:any) {
    return axiosInstance.get(`${this.url()}/groups/all/`+device_id)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteItem(id: number) {
    return axiosInstance.delete(`${this.url()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteItems(ids: number[]) {
    return axiosInstance.post(`${this.url()}/delete`, ids)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  attachAccount(accId: number, devId: number) {
    return axiosInstance.patch(`${this.url()}/${devId}/account/attach`, accId)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  attachLocation(zoneId: number, devId: number) {
    return axiosInstance.patch(`${this.url()}/${devId}/location/attach`, zoneId)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  detachLocation(devId: number) {
    return axiosInstance.patch(`${this.url()}/${devId}/location/detach`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  attachDashboard(deviceId: any, dashboardId: any) {
    return axiosInstance.post(`${this.url()}/${deviceId}/dashboard/${dashboardId}/dashbordAttach`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  createDeviceType(type: IDeviceType) {
    return axiosInstance.post(`${this.url()}/types`, type)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getSelectedDeviceType(id: number) {
    return axiosInstance.get(`${this.url()}/types/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  putDeviceType(type: IDeviceType) {
    return axiosInstance.put(`${this.url()}/types/${type.id}`, type)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deleteDeviceType(id: number) {
    return axiosInstance.delete(`${this.url()}/types/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  getDeviceValidationRules() {
    return axiosInstance.get(`device/validation`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  ping(devices: (number | null)[]) {
    return axiosInstance.post(`${this.url()}/rstb/ping`, {'devices': devices})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);

        return e.response.data;
      });
  }

  reboot(devices: (number | null)[]) {
    return axiosInstance.post(`${this.url()}/rstb/reboot`, {'devices': devices})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);

        return e.response.data;
      });
  }

  factoryReset(devices: (number | null)[]) {
    return axiosInstance.post(`${this.url()}/rstb/factory-reset`, {'devices': devices})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);

        return e.response.data;
      });
  }
  getDeviceInfo(devices: (number | null)[]) {
    return axiosInstance.post(`${this.url()}/rstb/device-information`, {'devices': devices})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deviceMacAddressesFromGroupId(deviceGroupsIds: number[]) {
    return axiosInstance.post(`${this.url()}/rstb/device-mac-from-group-id`, {'deviceGroupsIds': deviceGroupsIds})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  deviceCacheSettings(devices: (number | null)[], cacheSize: number) {
    return axiosInstance.post(`${this.url()}/rstb/device-cache-settings`, {'devices': devices,'cacheSize': cacheSize})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);

        return e.response.data;
      });
  }

  deviceCacheClear(devices: (number | null)[]) {
    return axiosInstance.post(`${this.url()}/rstb/device-cache-clear`, {'devices': devices})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);

        return e.response.data;
      });
  }
}
