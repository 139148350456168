
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import MultiSelect from 'vue-multiselect';
import virtualList from 'vue-virtual-scroll-list';
import Axios from 'axios';
import Editor from '@tinymce/tinymce-vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import axiosInstance from '@/helpers/axios';
import NevronInput from '@/components/NevronInput.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import NevronNewSourcePopup from "@/components/NevronNewSourcePopup.vue";
import AttachImage from "@/modules/Media/Attach.vue";
import MODModule from "@/stores/modules/MOD";
import MediaSources from "@/components/MediaContent/Sources.vue";

@Component({
  components: {
    AttachImage,
    NevronNewSourcePopup,
    virtualList,
    Editor,
    NevronHeader,
    NevronFooter,
    Skeleton,
    MultiSelect,
    NevronInput,
    NevronImageField,
    NevronAdvanceTextBox,
    MediaSources
  },
})
export default class MODTrailerDetails extends Vue {
  @Prop()
  module!: MODModule;
  @Prop()
  id!: any;
  @Prop()
  seriesId!: any;
  parentTye: string = 'series';
  sourceToEdit: IEpisodeSource | null = null;
  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  episodes: {
    image: null;
    imageId: null;
    releaseDate: string;
    rating: string;
    active: boolean;
    sort: number;
    shortDescription: string;
    fullDescription: string;
    parentId: null;
    duration: string;
    price: string;
    name: string;
    id: null;
    moduleId: null;
    isTrailer: boolean;
    sources: null;
  } = {
    id: null,
    active: true,
    isTrailer: false,
    sort: 0,
    name: '',
    shortDescription: '',
    fullDescription: '',
    image: null,
    imageId: null,
    releaseDate: '',
    price: '0',
    rating: '',
    duration: '',
    moduleId: null,
    parentId: null,
    sources: null,
  };
  sourcesAlert: any = {
    result: null,
    message: '',
  };
  categories: any[] = [];

  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;
  deviceTypes: IDeviceType[] = [];
  sourceTypes: ISourcesType[] = [];
  authorizationTypes: IAuthorizationType[] = [];
  autocompleteItems: any[] = [];
  selectedFolderId: any = 1;
  contentUrl: any = '';
  // editor: any = ClassicEditor;
  imageUrl: any = '';
  autocompleteCatItems: any[] = [];
  catTag: string = '';
  itemName: string = '';

  /**
   * Delete the Episode from this season
   */
  deleteEpisode() {
    return this.module.deleteEpisode(this.seriesId, this.episodes?.id!)
      .then((response: any) => {
        this.$router.go(-1);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  saveAndReload() {
    return axiosInstance.put(`series/${this.module.id}/${this.parentTye}/${this.$route.params.movieID}/episodes/${this.episodes.id}`, this.episodes)
      .then((response) => {
        if (response.status === 200) {
          showToaster('success', Vue.prototype.translate(this.episodes.name), 'successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.episodes.name), 'Fail to update', response);
        }
        this.loadPage();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.loadPage();
    this.itemName = this.module.itemsName;
  }


  @Watch('$route.params.id', {immediate: true})
  loadPage() {
    this.getPage(Number(this.$route.params.id));
    this.seriesId = Number(this.$route.params.movieID);
    this.contentUrl = '';
  }

  getPage(id: number) {
    return this.module.getEpisodesDetails(id, Number(this.$route.params.seasonsId) ?
      Number(this.$route.params.seasonsId) : Number(this.$route.params.movieID), this.parentTye)
      .then((response) => {
        this.episodes = response;
      })
      .catch((error) => {
        console.error((error));
      });
  }

  getAutocompleteCategories(text: string = '') {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();
    if (!this.searchInProgress) {
      this.searchInProgress = true;
      setTimeout(() => {
        return this.module.getAutocompleteCategories(this.searchQuery, this.source.token)
          .then((response) => {
            this.categories = [];
            for (const index in response) {
              if (response[index]) {
                this.categories.push(Vue.prototype.translate(response[index].name));
              }
            }
            this.searchInProgress = false;
          })
          .catch((e) => {
            console.error(e);
          });
      }, 500);
    } else {
      this.source.cancel();
    }
  }

  imageSelected(image: IMediaItem) {
    if (this.episodes) {
      // @ts-ignore
      this.episodes?.imageId = (image) ? image.id : null;
    }
  }


  onMediaTypeClick(type: string) {
    if (type === 'media-library') {
      //@ts-ignore
      this.$refs.updateSourceUrl.$children[0].open();
    }
  }

  updateSourceUrl(source: any) {
    this.sourceToEdit!.source = source.result.imageUrl;
  }
}
