
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import MultiSelect from 'vue-multiselect';
import NevronImageField from '@/components/NevronImageField.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import Editor from '@tinymce/tinymce-vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import session from '@/stores/Session';
import axiosInstance from '@/helpers/axios';
import NevronInput from '@/components/NevronInput.vue';

import SeriesModule from '@/stores/modules/Series';
import CreateSeasons from '../../../modules/Series/List/Seasons/Create.vue';
import CreateTrailor from '../../../modules/Series/List/Seasons/Episodes/create.vue';
import AttachCrew from './attachCrew.vue';
import router from '@/router';
import {showToaster} from '@/components/DashboardComposer/helpers';
import TablePopupModal from '@/components/NevronTablePopupModal.vue';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import AODModule from "@/stores/modules/AOD";
import NevronNewSourcePopup from "@/components/NevronNewSourcePopup.vue";
import {bus, translate} from "@/helpers/functions";

@Component({
  components: {
    NevronNewSourcePopup,
    virtualList,
    Editor,
    NevronHeader,
    NevronFooter,
    Skeleton,
    // ClassicEditor,
    MultiSelect,
    NevronInput,
    CreateSeasons,
    CreateTrailor,
    AttachCrew,
    NevronImageField,
    TablePopupModal,
    NevronAdvanceTextBox,
  },
})
export default class AODAodDetails extends Vue {
  @Prop()
  module!: AODModule;

  parentTye: string = 'series';
  episodeType: string = 'trailer';
  crewMetaKey: string = 'crewEdit';
  castMetaKey: string = 'castEdit';
  stores = stores;

  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  // @ts-ignore
  aod: IAod | null = null;
  allCategories: ICategories[] = [];
  allPackages: IPackage[] = [];
  seasons: any[] = [];
  trailors: any[] = [];
  categoryTags: any[] = [];
  sourceToEdit: IVideoSource | null = null;

  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;

  autocompleteItems: any[] = [];
  selectedFolderId: any = 1;
  contentUrl: any = '';
  catTag: string = '';

  itemName: string = '';
  editItem: any = '';
  pivotValue: any = '';

  deviceTypes: IDeviceType[] = [];
  sourceTypes: ISourcesType[] = [];
  authorizationTypes: IAuthorizationType[] = [];

  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.loadPage();
  }

  deleteSource(id: any) {
    // @ts-ignore
    if (confirm('Do you really want to delete this Source?')) {
      return this.module.deleteSource(Number(this.$route.params.id), id)
        .then(() => {
          this.loadPage();
        })
        .catch((e: any) => {
          console.error(e);
        }).finally(() => {
          showToaster('success', '', 'successfully updated');
          this.loadPage();
        });
    }
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.loadPage();
    this.getSourceData();
    this.itemName = this.module.itemsName;
  }

  loadPage() {
    this.getAOD(Number(this.$route.params.id));
    this.getPackages();
    this.getCategories();
    this.contentUrl = '';
  }

  openSources(e: any) {
    e.preventDefault();
    this.getSourceData();
    // @ts-ignore
    this.$refs.source.$children[0].open();
  }

  selectedSource(source: IVideoSource) {
    this.sourceToEdit = source;
  }

  editExistingSource(source: any) {
    if (this.aod && this.aod.id) {
      return this.module.editExistingSource(this.aod.id, source)
        .then(() => {
          this.sourceToEdit = null;
          this.loadPage();
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  async createNewSource(sources: IContentSource[]) {

    if (!this.aod || !this.aod.id) {
      return;
    }

    for (const source of sources) {
      try {
        const videoSource = source as IVideoSource;
        videoSource.movieId = this.aod.id;
        videoSource.sourceableType =  'App\\Movie';
        const response = await this.module.createNewSource(this.aod.id, videoSource);
        if (response) {
          showToaster('success', '', response.data)
        }
      } catch (e) {
        console.error(e);
      }
    }

    this.loadPage();
  }

  cancelEditSource() {
    this.sourceToEdit = null;
  }

  loadFullPage() {
    this.loadPage();
  }

  imageSelected(image: IMediaItem) {
    if (this.aod) {
      this.aod.imageId = (image) ? image.id : null;
    }
  }

  /**
   * Delete the Aod from this account
   */
  deleteAod() {
    return this.module.deleteItem(this.aod?.id!)
      .then((response: any) => {
        if (!response.success) {
          throw new Error(response.error.message);
        } else {
          showToaster('success', 'Aod Item', 'removed successfully.')
          this.$router.push({name: 'aod.index', params: {moduleId: this.$route.params.moduleId}});
        }
      })
      .catch((error: any) => {
        showToaster('danger', 'Error', error);
        console.log(error);
      });
  }

  detachCrew(selectedCrew: any, aodId: number, e: any) {
    e.preventDefault();
    if (this.aod && confirm('Do you really want to detach this Crew?')) {
      return this.module.detachCrew(selectedCrew, aodId)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  detachCast(selectedCast: any, aodId: number, e: any) {
    e.preventDefault();
    if (this.aod && confirm('Do you really want to detach this Crew?')) {
      return this.module.detachCast(selectedCast, aodId)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  detachTrailer(id: any, aodId: number, e: any) {
    e.preventDefault();
    if (this.aod && confirm('Do you really want to delete this Trailor?')) {
      return this.module.detachTrailer(this.parentTye, this.aod.id, id)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  openEpisodeModel() {
    this.episodeType = 'trailer';
    localStorage.setItem('episodeTypes', 'trailer');
    // @ts-ignore
    this.$refs.createEpisodes.$children[0].open();
  }

  attach(a: any) {
    // @ts-ignore
    this.application.tags.push({text: a.tag.text});
    this.catTag = '';
  }

  submitForm(p: IAod) {
    const form = document.getElementById('update-aod-form') as HTMLFormElement;
    if (form.checkValidity()) {
      this.saveAndReload(p);
    } else {
      showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
    }
    form.classList.add('was-validated');
  }

  saveAndReload(item: IAod) {
    return this.module.updateItem(item.id!, item)
      .then(async (response) => {
        if (response.responseCode === 200) {
          // await this.getAOD(Number(this.$route.params.id));
          // @ts-ignore
          showToaster('success', Vue.prototype.translate(this.aod.name), 'successfully updated');
        } else {
          // @ts-ignore
          showToaster('danger', Vue.prototype.translate(this.aod.name), 'Fail to update', response.code);
        }
        this.loadPage();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @Watch('module', {immediate: true})
  LoadpageDetail() {
    this.episodeType = 'trailer';
    this.loadPage();
  }

  getAOD(id: number) {
    return this.module.getAODDetails(id)
      .then((response) => {
        this.aod = response;
        this.aod!.categories.forEach((category: ICategories) =>
          category.name = Vue.prototype.translate(category.name));
        this.aod?.packages?.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));

      })
      .catch((error) => {
        console.log((error));
      });
  }

  getPackages() {
    stores.Package.getAllPackages()
      .then((response: any) => {
        this.allPackages = response;
        this.allPackages.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));
      });
  }

  getCategories() {
    this.module.getAllCategories()
      .then((response: any) => {
        if (response) {
          this.allCategories = response.data;
          this.allCategories.forEach((category: ICategories) =>
            category.name = Vue.prototype.translate(category.name));
        }
      });
  }

  updateCrewPosition(item: any) {
    return this.module.updateCrew(this.module.id, this.aod!.id!, this.pivotValue.id, item)
      .then((response) => {
        if (response.responseCode === 200) {
          showToaster('success', Vue.prototype.translate(this.pivotValue.name), 'Successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.pivotValue.name), 'Fail to update');
        }
        this.loadPage();
      });
  }

  updateCastPosition(item: any) {
    return this.module.updateCast(this.module.id, this.aod!.id!, this.pivotValue.id, item)
      .then((response) => {
        if (response.responseCode === 200) {
          showToaster('success', Vue.prototype.translate(this.pivotValue.name), 'Successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.pivotValue.name), 'Fail to update');
        }
        this.loadPage();
      });
  }

  openCrewPositionPopup(item: any) {
    this.editItem = item.pivot.role;
    this.pivotValue = item;
    // @ts-ignore
    this.$refs.crewEditPopup.$children[0].open();
  }

  getSourceData(e: any = null) {
    if (e) {
      e.preventDefault();
    }

    Promise.all([
      stores.modules.getSourceTypes(),
      stores.modules.getVideoSourceTypes(),
      stores.modules.getAuthorizationTypes()
    ]).then(([sourceTypes, videoSourceTypes, authorizationTypes]) => {
      this.deviceTypes = sourceTypes;
      this.sourceTypes = videoSourceTypes;

      bus().$emit('loadSourcePopup', {
        deviceTypes: sourceTypes,
        videoSourceTypes: videoSourceTypes,
        authorizationTypes: authorizationTypes
      });
    });
  }
  cancelEdit() {
    this.sourceToEdit = null;
  }

}
