
import {Component, Vue, Watch} from 'vue-property-decorator';
import DeviceEdit from '@/modules/Devices/Edit.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import TimeZones from '@/modules/TimeZones.vue';
import NevronInput from '@/components/NevronInput.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import MultiSelect, { Multiselect } from "vue-multiselect";
import globalConfig from "@/stores/GlobalConfig";
import {translate, validateForm} from "@/helpers/functions";
@Component({
  methods: {translate},
  components: {
    DeviceEdit,
    virtualList,
    Skeleton,
    NevronHeader,
    NevronFooter,
    TimeZones,
    NevronInput,
    TableModalGeneric,
    TableGeneric,
    MultiSelect
  },
  computed: {
    showFeature(){
      return globalConfig.config.showBetaFeatures
    }
  },
})

export default class DeviceDetails extends Vue {

  showConfirm: boolean = false;
  showTimeZoneDropdown: boolean = false;
  device: IDevice | null = null;
  account: IAccount[] = [];
  zones: any = null;
  fullRes: any = null;
  locationRes: any = null;
  searchQuery: string = '';
  CancelToken: any = Axios.CancelToken;
  source: any;
  searchInProgress: boolean = false;
  deviceTypes: IDeviceType[] = [];
  deviceGroup: IDeviceGroup[] = [];
  deviceGroupId: any = [];
  fullDashboardResponse: any = null;
  stores = stores;
  initialCacheEnabled: boolean | null = null;
  /**
   *
   */
  @Watch('$route', {immediate: false, deep: true})
  changePage() {
    this.reload(Number(this.$route.params.id));
  }

  save() {
    if (this.device && validateForm("device-network-form")) {
      const data = {
        ip: this.device.ip,
        macEth: this.device.macEth,
        macWifi: this.device.macWifi,
        rs_232: this.device.rs232,
        overrideTimeZone: this.showTimeZoneDropdown,
        timeZone: this.device.timeZone,
        firmware: this.device.firmware,
        apk: this.device.apk,
        manufacturer: this.device.manufacturer,
        serialNumber: this.device.serialNumber,
        name: this.device.name,
        locationId: this.zones ? this.zones.id : null,
        deviceGroupId: this.deviceGroupId,
        cacheEnabled: this.device.cacheEnabled,
        cacheSize: this.device.cacheSize,
        hotspotSsid: this.device.hotspotSsid,
        hotspotPassword: this.device.hotspotPassword,
        castingEnabled: this.device.castingEnabled,
      };


      if (this.device.cacheEnabled) {
        stores.Devices.deviceCacheSettings([this.device.id], this.device.cacheSize);
      } else {
        stores.Devices.deviceCacheSettings([this.device.id], 0);
      }


      return stores.Devices.updateItem(this.device?.id, data)
        .then((response) => {
          const deviceName = this.device?.name;
          const deviceMac = this.device?.macEth;

          if (response.responseCode === 200) {
            const message = deviceName ? Vue.prototype.translate(deviceName) : deviceMac;
            showToaster('success', 'successfully updated', message);
            this.showTimeZoneDropdown = response.data.timeZoneChanged;
            this.initialCacheEnabled = this.device?.cacheEnabled ?? null;
          } else {
            if (response.error.message) {
              showToaster('danger', '' , response.error.message);
            } else {
              const message = deviceName ? Vue.prototype.translate(deviceName) : deviceMac;
              showToaster('danger', message, 'Fail to update', response.code);
            }
          }
        }).catch((e: any) => {
          console.log(e);
          const deviceName = this.device?.name;
          const deviceMac = this.device?.macEth;
          const message = deviceName ? Vue.prototype.translate(deviceName) : deviceMac;
          showToaster('danger', message, 'Fail to update');
        });

    }
  }

  timeZoneSelected(zone: string) {
    if (this.device) {
      this.device.timeZone = zone;
    }
  }

  saveEdit(newDevice: IDevice) {
    if (newDevice && newDevice.id ) {
      return stores.Devices.saveDeviceEdit(newDevice)
        .then((response: any) => {
          console.log('You have successfully edited a device!');
          this.reload(response.id);
          // @ts-ignore
          this.$refs.edit.$children[0].close();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  attachDevices(account: IAccount) {
    if (account && this.device && this.device.id) {
      return stores.Devices.attachAccount(account.id!, this.device.id)
        .then((response: any) => {
          console.log('Successfully attached');
          // @ts-ignore
          this.$refs.accounts.$children[0].close();
          this.reload(Number(this.$route.params.id));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  get currentModuleId(): number {
    return Number(this.$route.params.id);
  }

  attachLocation(zone: IRooms[]) {
    this.zones = zone;
  }

  detachLocation(zone: IItem) {
    this.zones = null;
  }

  get locationIds() {
    return this.zones.map((el: IItem) => el.id);
  }

  get getTypeName() {
    const type = this.deviceTypes.find((type) => type.id === this.device?.deviceTypeId);
    return type?type.default:null;
  }

  getAccountsOnPage(index: number) {
    return stores.accounts.fetchData(index, null, 10)
      .then((response: any) => {
        this.fullRes = response;
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  attacheDashboard() {
    stores.dashboards
      .fetchData(1, '', 1000000, 'Mobile')
      .then((response) => {
        this.fullDashboardResponse = response;
        // @ts-ignore
        this.$refs.dashboard.$children[0].open();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  detachAccount() {
    if (this.device && this.device.id) {
      return stores.Devices.detachAccount(this.device.id)
        .then((response: any) => {
          console.log('Successfully detached');
          // @ts-ignore
          this.reload(Number(this.$route.params.id));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  getLocationListToAttach(e: any) {
    // e.preventDefault();
    return stores.Rooms.fetchData(null, null, 10)
      .then((response: any) => {
        this.locationRes = response;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  removeDevice() {
    return stores.Devices.deleteItem(this.device?.id!)
      .then((response: any) => {
        console.log('You have successfully deleted this device!');
        // @ts-ignore
        this.$refs.edit.$children[0].close();
        this.$router.push('/devices');
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  reload(id: number) {
    return stores.Devices.getSelectedDevice(id)
      .then((response: any) => {
        this.device = response;
        this.showTimeZoneDropdown = response.timeZoneChanged;
        if (this.device && this.device.id) {
          return stores.Devices.getAccountForDevice(this.device.id);
        }
      })
      .then((response: any) => {
        if (Object.entries(response).length > 0 && response.constructor === Object) {
          this.account = response;
        }else{
          this.account = [];
        }
        if (this.device && this.device.id) {
          return stores.Devices.getLocationForDevice(this.device.id);
        }
      })
      .then((response: any) => {
        if (Object.entries(response).length > 0 && response.constructor === Object) {
          this.zones = response;
        }
        return stores.Devices.getAllDeviceTypesFull();
      })
      .then((response: any) => {
        this.deviceTypes = response;
        return stores.Devices.getAllDeviceGroup(this.device?.id);
      })
      .then((response: any) => {
          this.deviceGroup = response.deviceGroup.map((group: { id: number, name: string }) => ({
          value: group.id,
          lable: Vue.prototype.translate(group.name),
          }));
          this.deviceGroupId = response.deviceHasGroup.map((group: { id: number, name: string }) => ({
          value: group.id,
          lable: Vue.prototype.translate(group.name),
        }));
          // this.deviceGroupId = response.deviceHasGroup
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  onActionClick(action: string) {

    if(!this.device) {
      return;
    }

    const macAddresses = [this.device.id];
    let response = null;
    let popupType = '';
    switch (action) {
      case 'ping':
        response = stores.Devices.ping(macAddresses);
        popupType = Vue.prototype.translate("ping");
        break;
      case 'reboot':
        response = stores.Devices.reboot(macAddresses);
        popupType = Vue.prototype.translate("reboot");
        break;
      case 'getDeviceInfo': // Gets info from device
        response = stores.Devices.getDeviceInfo(macAddresses);
        popupType = Vue.prototype.translate("devices-operation-success-message");
        break;
      case 'deviceCacheClear':
        response = stores.Devices.deviceCacheClear(macAddresses);
      case 'deviceSetCache':
        response = stores.Devices.deviceCacheSettings(macAddresses, this.device.cacheSize);
        break;
      default:
        break;
    }
    if (response !== null) {
      response.then((data:any) => {
        if(data.responseCode == 200) {
          showToaster('success', popupType, data.data);
        } else {
          showToaster('warning', popupType, data.error.message, data.responseCode);
        }
      }).catch((error:any) => {
        console.error('An error occurred:', error);
        showToaster('danger', popupType, error.message);
      });
    }
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.reload(Number(this.$route.params.id));
  }

  created() {
    if (this.device) {
      this.initialCacheEnabled = this.device.cacheEnabled;
    }
  }
}
