<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="menu-title">{{ translate('general') }}</li>
      <hr class="bg-secondary divider-line">

      <li>
        <router-link class="side-nav-link-ref" to="/dashboard">
          <icon type="home"/>
          <span>{{ translate('home') }}</span>
        </router-link>
      </li>

      <!-- CUSTOMERS-->
      <li class="">
        <router-link :to="{name: 'guests'}" v-if="hasFeaturePermissionForType('CUSTOMERS')"
           class="side-nav-link-ref">
          <icon type="customer"/>
          <span>{{ translate('guests') }}</span>
        </router-link>
      </li>

      <!-- PROPERTY-->
      <li>
        <a aria-expanded="false" class="is-parent has-arrow" v-if="hasFeaturePermissionForType('PROPERTY_ASSETS')">
          <icon type="property"/>
          <span>{{ translate('rooms-and-devices') }}</span>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <!-- Property submenu -->
          <li>
            <router-link :to="{name: 'rooms'}" class="side-nav-link-ref">
              <span>{{ translate('rooms') }}</span>
            </router-link>
          </li>
          <!-- Devices submenu -->
          <li>
            <router-link :to="{name: 'devices.index'}" class="side-nav-link-ref">
              <span>{{ translate('devices') }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'accounts'}" class="side-nav-link-ref">
              <span>{{ translate('accounts') }}</span>
            </router-link>
          </li>
        </ul>
      </li>


      <!-- DASHBOARDS-->
      <li class="">
        <router-link :to="{name: 'dashboards'}" v-if="hasFeaturePermissionForType('STB_DASHBOARDS') || hasFeaturePermissionForType('MOBILE_DASHBOARDS')"
                     class="side-nav-link-ref">
          <icon type="dashboard"/>
          <span>{{ translate('dashboards') }}</span>
        </router-link>
      </li>

      <!-- WELCOME SCREEN -->
      <li v-if="hasFeaturePermissionForType('WELCOME_SCREEN') && showFeature">
        <router-link class="side-nav-link-ref" :to="{name: 'welcomeScreen.index'}">
          <icon type="welcomeScreen"/>
          <span>{{ translate('welcome-screen') }}</span>
        </router-link>
      </li>

      <!-- PACKAGES -->
      <li v-if="hasFeaturePermissionForType('PACKAGES')">
        <router-link :to="{name: 'packages', params:{folderId: 1}}" class="side-nav-link-ref">
          <icon type="packages"/>
          <span>
                {{ translate('packages') }}
              </span>
        </router-link>
      </li>

      <!-- MEDIA LIBRARY-->
      <li v-if="hasFeaturePermissionForType('MEDIA_LIBRARY')">
        <router-link :to="{name: 'media.folder', params:{folderId: 0}}" class="side-nav-link-ref">
          <icon type="mediaLibrary"/>
          <span>
            {{ translate('media-library') }}
          </span>
        </router-link>
      </li>

      <!-- ORDERS AND RESERVATIONS -->
      <li v-if="hasFeaturePermissionForType('ORDERS_AND_RESERVATIONS')">
        <router-link :to="{name: 'orders.index'}" class="side-nav-link-ref">
          <icon type="cart"/>
          <span>
            {{ translate('orders-and-reservations') }}
          </span>
        </router-link>
      </li>


      <!-- SECTION: MODULES -->
      <li v-if="anyPermissionForAnyModule()" class="menu-title">
        {{ translate('modules') }}
      </li>
      <hr v-if="anyPermissionForAnyModule()" class="bg-secondary divider-line">

      <!-- CATALOGUES -->
      <li v-if="hasModulePermissionForType('CATALOGUE')">
        <a :class="{'has-arrow': hasModulesOfType('CATALOGUE')}" aria-expanded="false"
           @click="onParentMenuClick('CATALOGUE', $event)"
           class="is-parent has-add">
          <icon type="catalogues"/>
          <span>{{ translate('catalogues') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('CATALOGUE')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('CATALOGUE')">
            <li :class="{'active': isModuleActive(module)}">
              <router-link :to="{name: 'catalogue.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
                <icon class="ml-2" type="copy" @click="openDuplicateModal(module)"/>
                <icon class="ml-auto text-danger" type="sidebarItemInactive" v-if="!isModuleActive(module)" />
              </router-link>
            </li>
          </template>
        </ul>
      </li>
      <!-- TV CHANNELS -->
      <li v-if="hasModulePermissionForType('TV_RADIO')">
        <a :class="{'has-arrow': hasModulesOfType('TV_RADIO')}" aria-expanded="false"
           @click="onParentMenuClick('TV_RADIO', $event)"
           class="is-parent has-add">
          <icon type="tv"/>
          <span>{{ translate('tv-channels') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('TV_RADIO')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('TV_RADIO')">
            <li>
              <router-link :to="{name: 'tv.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
                <icon class="ml-2" type="copy" @click="openDuplicateModal(module)"/>
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- LIVE CAMERAS -->
      <li style="display: none" v-if="true || hasModulePermissionForType('LIVE_CAMERAS')">
        <router-link class="side-nav-link-ref" to="/404">
          <icon type="liveCameras"/>
          <span>{{ translate('live-cameras') }}</span>
        </router-link>
      </li>

      <!-- VOD -->
      <li v-if="hasModulePermissionForType('VOD')">
        <a :class="{'has-arrow': hasModulesOfType('MOD') || hasModulesOfType('SERIES')}"
           aria-expanded="false"
           @click="onParentMenuClick('VOD', $event)"
           class="is-parent has-add">
          <icon type="vod"/>
          <span>{{ translate('vod') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('VOD')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('MOD')">
            <li>
              <router-link :to="{name: 'mod.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
                <icon class="ml-2" type="copy" @click="openDuplicateModal(module)"/>
              </router-link>
            </li>
          </template>
          <template v-for="module in getModulesOfType('SERIES')">
            <li>
              <router-link :to="{name: 'series.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
                <icon class="ml-2" type="copy" @click="openDuplicateModal(module)"/>
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- VIDEO -->
      <li v-if="hasModulePermissionForType('VIDEO')">
        <a :class="{'has-arrow': hasModulesOfType('VIDEO')}" aria-expanded="false"
           @click="onParentMenuClick('VIDEO', $event)"
           class="is-parent has-add">
          <icon type="video"/>
          <span>{{ translate('video') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('VIDEO')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('VIDEO')">
            <li>
              <router-link class="side-nav-link-ref"
                           :to="{name: 'video.index', params: {moduleId: module.id}}">
                {{ translate(module.name) }}
                <icon class="ml-2" type="copy" @click="openDuplicateModal(module)"/>
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- AOD -->
      <li v-if="hasModulePermissionForType('AOD')">
        <a :class="{'has-arrow': hasModulesOfType('AOD')}" aria-expanded="false"
           @click="onParentMenuClick('AOD', $event)"
           class="is-parent has-add">
          <icon type="aod"/>
          <span>{{ translate('aod') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('AOD')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('AOD')">
            <li>
              <router-link class="side-nav-link-ref"
                           :to="{name: 'aod.index', params: {moduleId: module.id}}">
                {{ translate(module.name) }}
                <icon class="ml-2" type="copy" @click="openDuplicateModal(module)"/>
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- RSS -->
      <li v-if="hasModulePermissionForType('RSS')">
        <router-link class="side-nav-link-ref" :to="{name: 'rss.index'}">
          <icon type="cast"/>
          <span>{{ translate('rss') }}</span>
        </router-link>
      </li>

      <!-- ESSENTIALS -->
      <li>
        <a v-if="anyPermissionForGuestFlowType()" aria-expanded="false" class="is-parent has-arrow">
          <icon type="essentials"/>
          <span>{{ translate('essentials') }}</span>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <li v-if="hasPermissionForGuestFlowType('BASIC_PROFILING')">
            <router-link :to="{name: 'interests.index', params: {id: 0}}"
                         class="side-nav-link-ref">
              <span>{{ translate('my-interests') }}</span>
            </router-link>
          </li>
          <li v-if="hasPermissionForGuestFlowType('ACCOMODATION_LIBRARY')">
            <router-link class="side-nav-link-ref" :to="{name: 'itineraries.index'}">
              <span>{{ translate('itineraries') }}</span>
            </router-link>
          </li>
          <li v-if="hasPermissionForGuestFlowType('RATINGS')">
            <router-link class="side-nav-link-ref" :to="{name: 'ratings.index'}">
              <span>{{ translate('ratings') }}</span>
            </router-link>
          </li>
          <li v-if="hasPermissionForGuestFlowType('SOCIAL_MEDIA')">
            <router-link class="side-nav-link-ref" to="/social-media">
              <span>{{ translate('social-media') }}</span>
            </router-link>
          </li>
          <li v-if="hasPermissionForGuestFlowType('SAVE_PLANET')">
            <router-link class="side-nav-link-ref" :to="{name: 'skipCleaning'}">
              <span>{{ translate('skip-cleaning') }}</span>
            </router-link>
          </li>
          <li v-if="hasPermissionForGuestFlowType('PROMOTIONS')">
            <router-link class="side-nav-link-ref" to="/promotions">
              <span>{{ translate('promotions') }}</span>
            </router-link>
          </li>
          <li v-if="hasModulePermissionForType('CONTACTS')">
            <router-link class="side-nav-link-ref" to="/contact/module">
              <span>{{ translate('contacts') }}</span>
            </router-link>
          </li>
          <li style="display: none">
            <router-link class="side-nav-link-ref" to="/404">
              <span>{{ translate('prayer-time') }}</span>
            </router-link>
          </li>
        </ul>
      </li>

      <!-- ROOM CARE -->
      <li v-if="hasModulePermissionForType('SERVICE') && roomCareModule">
        <router-link class="side-nav-link-ref" :to="{name: 'service.index', params: {moduleId: roomCareModule.id}}">
          <icon type="roomCare"/>
          <span>{{ translate('room-care') }}</span>
        </router-link>
      </li>

      <!-- FAST CHECK IN -->
      <li v-if="hasPermissionForGuestFlowType('FAST_CHECK_IN')">
        <router-link class="side-nav-link-ref" :to="{name: 'fastCheckIn'}">
          <icon type="fastCheckIn"/>
          <span>{{ translate('fast-check-in') }}</span>
        </router-link>
      </li>

      <!-- MY MOOD -->
      <li v-if="hasPermissionForGuestFlowType('EMOTION_RECOGNITION')">
        <router-link class="side-nav-link-ref" :to="{name: 'moods.Index'}">
          <icon type="myMood"/>
          <span>{{ translate('my-mood') }}</span>
        </router-link>
      </li>

      <!-- SURVEY -->
      <li v-if="hasModulePermissionForType('SURVEYS')">
        <router-link class="side-nav-link-ref" :to="{name: 'surveys.index'}">
          <icon type="survey"/>
          <span>{{ translate('survey') }}</span>
        </router-link>
      </li>

      <!-- APPS-->
      <li v-if="hasModulePermissionForType('TV_APPLICATION')">
        <a
          :class="{'has-arrow':  hasModulesOfType('TV_APPLICATION')
        }" aria-expanded="false"
          @click="onParentMenuClick('TV_APPLICATION', $event)"
           class="is-parent has-add">
          <icon type="apps"/>
          <span>{{ translate('apps') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('TV_APPLICATION')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('TV_APPLICATION')">
            <li>
              <router-link :to="{name: 'applications.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
                <icon class="ml-2" type="copy" @click="openDuplicateModal(module)"/>
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- Web links-->
      <li v-if="hasModulePermissionForType('WEBSITE_APPLICATION')">
        <a
          :class="{'has-arrow':  hasModulesOfType('WEBSITE_APPLICATION')
        }" aria-expanded="false"
          @click="onParentMenuClick('WEBSITE_APPLICATION', $event)"
           class="is-parent has-add">
          <icon type="webLinks"/>
          <span>{{ translate('web-links') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('WEBSITE_APPLICATION')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">

          <template v-for="module in getModulesOfType('WEBSITE_APPLICATION')">
            <li>
              <router-link :to="{name: 'applications.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
                <icon class="ml-2" type="copy" @click="openDuplicateModal(module)"/>
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- WIDGETS -->
      <li v-if="hasModulePermissionForType('TIME') || hasModulePermissionForType('WEATHER')">
        <a :class="{'has-arrow': hasModulesOfType('TIME') || hasModulesOfType('WEATHER')}"
           aria-expanded="false"
           @click="onParentMenuClick('WIDGETS', $event)"
           class="is-parent has-add">
          <icon type="widgets"/>
          <span>{{ translate('widgets') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('WIDGETS')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('TIME')">
            <li>
              <router-link :to="{name: 'time.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
                <icon class="ml-2" type="copy" @click="openDuplicateModal(module)"/>
              </router-link>
            </li>
          </template>
          <template v-for="module in getModulesOfType('WEATHER')">
            <li>
              <router-link :to="{name: 'weather.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
                <icon class="ml-2" type="copy" @click="openDuplicateModal(module)"/>
              </router-link>
            </li>
          </template>
        </ul>
      </li>

<!--       User settings -->
      <li v-if="hasFeaturePermissionForType('USER_SETTINGS')">
        <router-link class="side-nav-link-ref" :to="{name: 'user.settings'}">
          <icon type="userSettings"/>
          <span>{{ translate('user-settings') }}</span>
        </router-link>
      </li>

      <!-- Messaging -->
      <li v-if="hasFeaturePermissionForType('MESSAGING') ">
        <router-link class="side-nav-link-ref" :to="{name: 'messaging'}">
          <icon type="notifications"/>
          <span>{{ translate('messaging') }}</span>
        </router-link>
      </li>

      <!-- DIGITAL SIGNAGE -->
      <li v-if="hasModulePermissionForType('DIGITAL_SIGNAGE')">
        <router-link class="side-nav-link-ref" :to="{name: 'digitalSignage.index'}">
          <icon type="digitalSignageMenu"/>
          <span>{{ translate('digital-signage') }}</span>
        </router-link>
      </li>

      <!-- SHIP POSITION -->
      <li v-if="hasModulePermissionForType('SHIP_POSITION_APPLICATION') && shipPositionModule">
        <router-link class="side-nav-link-ref" :to="{name: 'applications.index', params: {moduleId: shipPositionModule.id}}">
          <icon type="shipPosition"/>
          <span>{{ translate('ship-position') }}</span>
        </router-link>
      </li>

      <!-- CASTING -->
      <li style="display: none">
        <router-link class="side-nav-link-ref" to="/404">
          <icon type="cast"/>
          <span>{{ translate('casting') }}</span>
        </router-link>
      </li>

      <!-- CURRENCY EXCHANGE -->
      <li v-if="hasModulePermissionForType('CURRENCY_EXCHANGE')">
        <router-link class="side-nav-link-ref" :to="{name: 'currencyExchange.index'}">
          <icon type="currencyExchange"/>
          <span>{{ translate('currency-exchange') }}</span>
        </router-link>
      </li>

      <!-- SECTION: SYSTEM SETTINGS -->
      <template v-if="showSystemSettings">
        <li class="menu-title">
          {{ translate('system-settings') }}
        </li>
        <hr class="bg-secondary divider-line">
      </template>

      <li v-if="hasFeaturePermissionForType('SETTINGS')" class="">
        <a aria-expanded="false" class="is-parent has-arrow">
          <icon type="settings"/>
          <span>
            {{ translate('settings') }}
          </span>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <li>
            <router-link :to="{name: 'general.setting'}" class="side-nav-link-ref">
              {{ translate('general-title') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'app.setting'}" class="side-nav-link-ref">
              {{ translate('project-configuration') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'analytic.setting'}" class="side-nav-link-ref">
              {{ translate('analytics') }}
            </router-link>
          </li>
          <!-- LOCALIZATION -->
          <li>
            <router-link aria-expanded="false" :to="{name: 'localization', params:{folderId: 1}}"
                         class="side-nav-link-ref">
              <i class="fas fa-language pr-2"></i>
              <span>
                {{ translate('language-and-translations') }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'system.services'}" class="side-nav-link-ref">
              <span>
                {{ translate('system-services') }}
              </span>
            </router-link>
          </li>
        </ul>
      </li>

      <!-- ACCESS & ROLES-->
      <li v-if="showRoleAndAccessModule" class="">
        <router-link :to="{name: 'access.roles', params:{folderId: 1}}" class="side-nav-link-ref">
          <icon type="accessRoles"/>
          <span>{{ translate('access-&-roles') }}</span>
        </router-link>
      </li>
    </ul>


    <CreateModule ref="createModule" :moduleType="createModuleType"/>
    <DuplicateModule ref="duplicateModule" :module="moduleToDuplicate"/>
  </div>
</template>


<script>
import MetisMenu from 'metismenujs/dist/metismenujs';
import stores from '@/stores';
import axiosInstance from '@/helpers/axios';
import globalConfig from '../../stores/GlobalConfig';
import CreateModule from '@/modules/Modules/Create.vue';
import NevronIcon from '../NevronIcons.vue';
import DuplicateModule from '@/modules/Modules/Duplicate.vue';
import {
  hasPermission,
  anyPermissionForAnyModule,
  hasFeaturePermissionForType,
  anyPermissionForGuestFlowType,
  hasModulePermissionForType,
  hasPermissionForGuestFlowType
} from '@/helpers/permissions';
import { bus } from '@/helpers/functions';

export default {
  components: {
    CreateModule,
    DuplicateModule,
    NevronIcon
  },
  data() {
    return {
      menuRef: null,
      modules: [],
      staticMenus: [],
      counter: 0,
      admin: stores.admin,
      timer: '',
      activeLink: null,
      createModuleType: null,
      moduleToDuplicate: null,
    };
  },
  watch: {
    $route(to, from) {
      this.menuManagement();
    },
  },
  computed: {
    menus() {
      return stores.modules.models;
    },
    showSystemSettings() {
      return this.hasFeaturePermissionForType('SETTINGS') || this.hasFeaturePermissionForType('ADMINS');
    },
    showRoleAndAccessModule() {
      if (stores.admin.isProjectAdmin && stores.admin.roleAccess){
        return true;
      } else if (!stores.admin.isProjectAdmin && hasFeaturePermissionForType('ADMINS')) {
        return true;
      }
      return false;
    },
    roomCareModule(){
      return this.getModulesOfType('SERVICE')[0];
    },
    shipPositionModule(){
      return this.getModulesOfType('SHIP_POSITION_APPLICATION')[0];
    },
    showFeature(){
      return globalConfig.config.showBetaFeatures
    }
  },
  created() {
  bus().$on('refreshSidebar', this.refresh);
  bus().$on('active', this.refresh);
  },
  mounted() {
    this.menuManagement().then(() => {
        this.menuRef = new MetisMenu("#side-menu");
      });
    stores.GuestFlow.listofServices()
      .then((response) => {
        this.staticMenus = response.data;
      });
  },
  methods: {
    anyPermissionForAnyModule,
    hasFeaturePermissionForType,
    hasPermissionForGuestFlowType,
    anyPermissionForGuestFlowType,
    hasModulePermissionForType,
    hasPermission,

    openCreateModulePopup(moduleType) {
      this.createModuleType = moduleType;
      this.$refs.createModule.$children[0].open();
    },

    getModulesOfType(moduleType) {
      return this.menus.filter(menu => (menu.type === moduleType));
    },
    isModuleActive(module) {
      let isActive = false;
      this.menus.forEach((menu) => {
        if(menu.id === module.id && module.type === menu.type && module.active == 1)
        {
          isActive = true;
        }
      });
      return isActive;
    },
    hasModulesOfType(moduleType) {
      const moduleMappings = {
        'APPLICATION': ['TV_APPLICATION', 'WEBSITE_APPLICATION'],
        'WIDGETS': ['TIME', 'WEATHER'],
        'VOD': ['SERIES', 'MOD']
      };
      if (moduleMappings[moduleType]) {
        return moduleMappings[moduleType].some(type => this.getModulesOfType(type).length > 0);
      }
      return this.getModulesOfType(moduleType).length > 0;
    },

    onParentMenuClick(moduleType, event) {
      if (!this.hasModulesOfType(moduleType)) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
    },
    // menuManagement() updates the styling of the side menu based on the current page
    async menuManagement() {
      await stores.modules.fetchPromise;
      this.$nextTick(() => {
        const previousActiveLink = this.activeLink;
        const links = this.$el.querySelectorAll(".side-nav-link-ref");
        links.forEach((link) => {
          if (link.pathname === window.location.pathname) {
            this.activeLink = link;
          }
        });

        if (!this.activeLink) return;
        if (this.activeLink !== previousActiveLink) this.collapseMenus();
        this.resetMenuStyles();

        this.activeLink.classList.add("active");
        let parent = this.activeLink.parentElement;
        while (parent && parent.id !== "side-menu") {
          if (parent.matches("li")) {
            parent.classList.add("mm-active");
          }
          if (parent.matches("ul.sub-menu")) {
            parent.classList.add("mm-show");
            const childAnchor = parent.parentElement.querySelector(".has-arrow");
            const childDropdown = parent.parentElement.querySelector(".has-dropdown");

            if (childAnchor) {
              childAnchor.classList.add("mm-active");
            }
            if (childDropdown) {
              childDropdown.classList.add("mm-active");
            }
          }
          parent = parent.parentElement;
        }
      });
    },
    resetMenuStyles() {
      const links = this.$el.querySelectorAll(".side-nav-link-ref");
      links.forEach((link) => {
        link.classList.remove("active");
      });

      const activeLinks = this.$el.querySelectorAll(".mm-active");
      activeLinks.forEach((link) => {
        link.classList.remove("mm-active");
      });
    },
    collapseMenus() {
      const showLinks = this.$el.querySelectorAll(".mm-show");
      showLinks.forEach((link) => {
        link.classList.remove("mm-show");
      });
    },
    refresh() {
      stores.modules.fetch();
    },
    openDuplicateModal(module) {
      this.moduleToDuplicate = { ... module};
      this.$refs.duplicateModule.$children[0].open();
    }
  },
};
</script>

<style scoped>
.nevron-icon {
  margin-right: 10px;
}

.nevron-icon.svg {
  width: 25px;
  height: 25px;
}

.is-parent {
  cursor: pointer;
}

.has-add {
  display: flex !important;
  align-items: center !important;
}

.has-add .nevron-icon.add {
  margin: 0 0 0 auto;
  padding: 5px;
}

.menu-title {
  padding-bottom: 0px !important;
}

.divider-line {
  margin: 5px 0;
}
</style>
