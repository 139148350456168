import AccountDelete from './modules/Accounts/Delete.vue';
import AccountsCreate from './modules/Accounts/Create.vue';
import AccountsShow from './modules/Accounts/Show.vue';
import AddressCreate from './modules/Customers/Address/Create.vue';
import AdminDelete from './modules/Admins/Delete.vue';
import Admins from './modules/Admins/Index.vue';
import AdminsCreate from './modules/Admins/Create.vue';
import AdminsShow from './modules/Admins/Show.vue';
import CustomRoles from './modules/CustomRoles/Index.vue';
import CustomRolesCreate from './modules/CustomRoles/Create.vue';
import CustomRolesShow from './modules/CustomRoles/Show.vue';
import CustomRolesDelete from './modules/CustomRoles/Delete.vue';
import CustomersCreate from './modules/Customers/Create.vue';
import CustomersDelete from './modules/Customers/Delete.vue';
import CustomersShow from './modules/Customers/Show.vue';
import StaysShow from './modules/Stays/Show.vue';
import StaysTypesShow from './modules/Stays/Types/Show.vue';
import Promotions from './modules/Promotions/List.vue';
import ShowPromotions from './modules/Promotions/Show.vue';
import AssetsShow from './modules/PropertyAssets/Assets/Show.vue';
import AssetsCategoryShow from './modules/PropertyAssets/Assets/Category/Show.vue';
import ContactModule from './modules/ContactModule/Show.vue';
import LocationShow from './modules/PropertyAssets/Locations/Zones/Show.vue';
import LocationsCategoryShow from './modules/PropertyAssets/Locations/Category/Show.vue';
import LibraryShow from './modules/PropertyAssets/Library/Show.vue';
import RatingPateformShow from './modules/PropertyAssets/Media/RatingPlateform/Show.vue';
import SocialMediaShow from './modules/PropertyAssets/Media/SocialMedia/Show.vue';
import CommentProposalsShow from './modules/PropertyAssets/Media/CommentProposals/Show.vue';
import GuestFlowModuleShow from './modules/GuestFlowModule/ShowBasic.vue';
import DeviceTypes from './modules/Devices/Types/Index.vue';
import DeviceTypesCreate from './modules/Devices/Types/Create.vue';
import DeviceTypesShow from './modules/Devices/Types/Show.vue';
import DeviceGroupShow from './modules/Devices/Group/Show.vue';
import Devices from './modules/Devices/Index.vue';
import DevicesCreate from './modules/Devices/Create.vue';
import DevicesShow from './modules/Devices/Show.vue';
import DevicyTypesDelete from './modules/Devices/Types/Delete.vue';
import FileDelete from './modules/Media/DeleteFile.vue';
import FolderDelete from './modules/Media/DeleteFolder.vue';
import Home from './modules/Home.vue';
import Login from './modules/Auth/Login.vue';
import TwoFactorAuthentication from './modules/Auth/TwoFactorAuthentication.vue';
import AdminTwoFactorAuthentication from './modules/AdminAuth/TwoFactorAuthentication.vue';
import AdminLogin from './modules/AdminAuth/Login.vue';
import Logout from './modules/Auth/Logout.vue';
import LostPassword from './modules/Auth/LostPassword.vue';
import MainMenu from './modules/MainMenu.vue';
import Media from './modules/Media/Index.vue';
import MediaShow from './modules/Media/Show.vue';
import File from './modules/File/Index.vue';
import FileShow from './modules/File/Show.vue';
import ModuleView from './views/ModuleView.vue';
import AdminView from './views/AdminView.vue';
import PackageCreate from './modules/Packages/Create.vue';
import PackageDelete from './modules/Packages/DeletePackage.vue';
import PackageDetails from './modules/Packages/Show.vue';
import EpgSources from './modules/Epgs/Index.vue';
import EpgSourcesCreate from './modules/Epgs/Create.vue';
import CatalogueShow from './modules/Pages/Items/Show.vue';
import ServiceShow from './modules/Service/Items/Show.vue';
import ApplicationsShow from './modules/Application/Items/Show.vue';
import ProfileCreate from './modules/Accounts/Profiles/CreateProfile.vue';
import ProfileDelete from './modules/Accounts/Profiles/Delete.vue';
import ProfileShow from './modules/Accounts/Profiles/Show.vue';
import Projects from './modules/Projects/Projects.vue';
import Recorders from './modules/Recorders/Index.vue';
import RecordersCreate from './modules/Recorders/Create.vue';
import RecordersShow from './modules/Recorders/Show.vue';
import RecordersStreamCreate from './modules/Recorders/Stream/Create.vue';
import ResetPassword from './modules/Auth/ResetPassword.vue';
import Router from 'vue-router';
import TVChannelsDelete from './modules/TV/Channels/Delete.vue';
import TVChannelsShow from './modules/TV/Channels/Show.vue';
import TVSeriesShow from './modules/Series/List/show.vue';
import MODShow from './modules/MOD/List/show.vue';
import seasionsShow from './modules/Series/List/Seasons/show.vue';
import episodesShow from './modules/Series/List/Seasons/Episodes/show.vue';
import trailerShow from './modules/MOD/List/Episodes/show.vue';
import TVModuleCreate from './modules/Modules/Create.vue';
import TranscoderStreams from './modules/Transcoder/Index.vue'; // @TODO TranscoderStreAms
import TranscoderStreamsCreate from './modules/Transcoder/Create.vue';
import TranscoderStreamsShow from './modules/Transcoder/Show.vue';
import UsersCreate from './modules/Users/Create.vue';
import UsersDelete from './modules/Users/Delete.vue';
import UsersShow from './modules/Users/Show.vue';
import VODMovieShow from './modules/VOD/Movies/Show.vue';
import RSSIndex from './modules/RSS/Index.vue'
import Vue from 'vue';
import {VueRouter} from 'vue-router/types/router';
import WeatherLocationsCreate from './modules/Weather/Create.vue';
import stores from '@/stores';

import CreateDashboard from './modules/Dashboards/Create.vue';
import ShowDashboard from './modules/Dashboards/Show.vue';
import DeleteDashboard from './modules/Dashboards/Delete.vue';
import DeletePanel from './modules/Dashboards/Panels/Delete.vue';
import CreateDashboardPanel from './modules/Dashboards/Panels/Create.vue';
import ShowPanel from './modules/Dashboards/Panels/Show.vue';
import CreatePanelElement from './modules/Dashboards/PanelElements/Create.vue';
import ShowPanelElement from './modules/Dashboards/PanelElements/Show.vue';
import DeletePanelElement from './modules/Dashboards/PanelElements/Delete.vue';
import Configuration from '@/modules/Configuration/index.vue';
import AppSetting from '@/modules/Settings/AppSetting.vue';
import Crew from './modules/CrewCast/Crew/Show.vue';
import Cast from './modules/CrewCast/Cast/Show.vue';
import MatomoSetting from '@/modules/Settings/MatomoSetting.vue';
import TimeServices from '@/modules/Settings/TimeServices.vue';
import GeneralSetting from '@/modules/Settings/GeneralSetting.vue';
import MailConfiguration from '@/modules/MailConfiguration/Index.vue';
import PaymentsConfiguration from '@/modules/Payments/Index.vue';
import UserSettings from '@/modules/UserSettings/UserSettings.vue';
import SmsConfiguration from '@/modules/SmsConfiguration/Index.vue';
import Other from '@/modules/Settings/Other.vue';
import ShowMobileComposer from '@/modules/Dashboards/Mobile/Composer/Show.vue';
import ShowSTBComposer from '@/modules/Dashboards/STB/Composer/Show.vue';
import SystemService from '@/modules/SystemServices/Index.vue';
import Localization from '@/modules/Localization/Index.vue';
import Customer from '@/modules/Customers/Index.vue';
import AccessRoles from '@/modules/AccessRoles/Index.vue';
import DashboardsIndex from '@/modules/Dashboards/Index.vue';
import PropertyIndex from '@/modules/PropertyAssets/Index.vue';
import ServiceCategoryShow from '@/modules/Service/Categories/edit.vue';

import ShowModule from './modules/Modules/Update.vue';
import ShowLanguage from './modules/Languages/Show.vue';
import CreateTranslation from './modules/Translation/Create.vue';
import TranslationList from './modules/Translation/Index.vue';
import ShowTranslation from './modules/Translation/Show.vue';
import NevronError from '@/components/NevronError.vue';
import InterestsIndex from './modules/Interests/Index.vue';
import ItinerariesIndex from './modules/Itineraries/Index.vue';
import RatingsIndex from './modules/Ratings/Index.vue';
import SocialMediaIndex from '@/modules/PropertyAssets/Media/SocialMedia/Index.vue';
// DYNAMIC COMPONENTS
import CategoriesIndexBasic from '@/components/ListNevronComponents/Categories/IndexBasic.vue';
import CategoriesShowBasic from '@/components/ShowNevronComponents/Categories/ShowBasic.vue';
import CategoriesShowMulti from '@/components/ShowNevronComponents/Categories/ShowMulti.vue';
import ItemsIndexGeneric from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import ItemsIndexGenericMultiTable
  from '@/components/ListNevronComponents/Items/IndexGenericMultiTable.vue';
import ModuleIndex from '@/components/ModuleIndex.vue';

import routerBase from '@/helpers/routerBase';
// @ts-ignore
import i18n from './i18n.js';
import globalConfig from '@/helpers/globalConfig';
import ShowGFMobileComposer from '@/modules/Dashboards/GFMobile/Composer/Show.vue';

// @ts-ignore
import aodTrailerShow from './modules/AOD/List/Episodes/show.vue';
import AODShow from './modules/AOD/List/show.vue';
import aodCategoryEdit from './modules/AOD/Categories/edit.vue';
import ProjectManagement from '@/modules/Projects/ProjectManagement.vue';
import ProjectFinish from '@/modules/Projects/ProjectFinish.vue';
import PromotionsIndex from '@/modules/Promotions/Index.vue';
import {canNavigateTo, hasPermission} from '@/helpers/permissions';
import {ensureInitialAdminData} from '@/helpers/adminInitialization';
import DevicesIndex from '@/modules/Devices/Index.vue';
import DigitalSignageIndex from '@/modules/DigitalSignage/Index.vue';
import WelcomeScreenIndex from '@/modules/WelcomeScreen/Index.vue';
import SurveysIndex from '@/modules/Surveys/Index.vue';
import SurveyQuestionsExport from '@/modules/Surveys/QuestionsExport.vue';
import ChatIndex from './modules/Chat/Index.vue';
import OrderReservations from './modules/OrderAndReservations/index.vue';
import MultiTenantUserShow from '@/modules/CombinedUser/User/Show.vue';
import OrdersIndex from './modules/Orders/index.vue';
import SuperAdminDevices from './modules/SuperAdminDevices/Devices.vue';
import SuperAdminUsers from './modules/SuperAdmin/Users/Index.vue';
import SuperAdminOperators from "@/modules/SuperAdminOperators/Operators.vue";
import SuperAdminOperatorProjects from "@/modules/SuperAdminOperators/OperatorProjects.vue";
import AdminProfilePage from './modules/SuperAdmin/Users/AdminProfilePage.vue';
import CurrencyExchangeIndex from "@/modules/CurrencyExchange/Index.vue";
import AdminInstructions from './modules/SuperAdmin/Instructions/Instructions.vue';

import MessageLogShow from './modules/Messaging/Show.vue';
import MoodsIndex from '@/modules/Moods/Index.vue';
import AdminRestPassword from './modules/AdminAuth/ResetPassword.vue';
import AdminLostPassword from './modules/AdminAuth/LostPassword.vue';
import SkipCleaningIndex from './modules/SkipCleaning/Index.vue';


Vue.use(Router);
// TODO: zakaj že rabimo base? https://jsfiddle.net/6xu48og7/1/

const router: VueRouter = new Router({
  mode: 'history',
  base: routerBase,
  routes: [
    {
      component: NevronError,
      name: '404',
      path: '*',
      props: {code: '404', message: 'not-found'},
      meta: {public: true},
    },
    {
      component: NevronError,
      name: '401',
      path: '/401',
      props: {code: 401, message: 'unauthorized'},
    },
    {
      path: '/admin',
      component: AdminView,
      children: [
        // public paths
        {
          component: Projects,
          name: 'projects',
          path: 'projects',
        },
        {
          component: ProjectManagement,
          name: 'projects.create',
          path: 'projects/create',
        },
        {
          component: ProjectManagement,
          name: 'projects.edit',
          path: 'projects/:id/edit',
        },
        {
          component: ProjectManagement,
          name: 'projects.copy',
          path: 'projects/:id/copy',
        },
        {
          component: ProjectFinish,
          name: 'projects.finish',
          path: 'projects/finish/:type',
        },
        {
          component: MainMenu,
          name: 'menu',
          path: 'menu',
        },
        {
          component: AdminRestPassword,
          meta: {public: true},
          name: 'password.reset',
          path: 'password/reset/:token',
        },
        {
          component: AdminLostPassword,
          meta: {public: true},
          name: 'password.lost',
          path: 'password/reset',
        },
        {
          component: AdminLogin,
          meta: {public: true},
          name: 'admin.login',
          path: 'login',
        },
        {
          component: AdminTwoFactorAuthentication,
          name: 'admin.2FA',
          path: '2fa',
        },
        {
          component: SuperAdminDevices,
          name: 'SuperAdminDevices',
          path: 'super-admin-devices',
        },
        {
          component: SuperAdminOperators,
          name: 'SuperAdminOperators',
          path: 'operators',
        },
        {
          component: SuperAdminOperatorProjects,
          name: 'SuperAdminOperatorProjects',
          path: 'operators/:id',
        },
        {
          component: SuperAdminUsers,
          name: 'superadmin.users',
          path: 'super-admin-users',
        },
        {
          component: AdminProfilePage,
          name: 'superadmin.page',
          path: 'super-admin-page',
        },
        {
          component: AdminInstructions,
          name: 'superadmin.instructions',
          path: 'super-admin-instructions',
        },
      ],

    },
    // project paths
    {
      component: Login,
      meta: {public: true},
      name: 'login',
      path: '/login',
    },
    {
      component: TwoFactorAuthentication,
      name: '2FA',
      path: '/2fa',
    },
    {
      component: ResetPassword,
      meta: {public: true},
      name: 'project.password.reset',
      path: '/password/reset/:token',
    },
    {
      component: LostPassword,
      meta: {public: true},
      name: 'project.password.lost',
      path: '/password/reset',
    },
    {
      component: ItemsIndexGeneric,
      name: 'combinedUser.index',
      path: '/combined-users',
      meta: {permission: 'feature.COMBINED_USER'},
    },
    {
      component: MultiTenantUserShow,
      name: 'combinedUser.show',
      path: '/combined-users/:id',
      meta: {permission: 'feature.COMBINED_USER'},
    },
    {
      path: '/',
      redirect: '/dashboard',
    },
    {
      name: 'translation.create',
      path: '/translation/create',
      component: CreateTranslation,
    },
    {
      name: 'translation.show',
      path: '/translation/:key',
      component: ShowTranslation,
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      name: 'translation.index',
      path: '/translation',
      component: TranslationList,
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      name: 'orders.index',
      path: '/orders',
      component: OrdersIndex,
      meta: {permission: 'feature.ORDERS_AND_RESERVATIONS'},
    },
    {
      component: ItemsIndexGeneric,
      name: 'modules.index',
      path: '/modules',
      props: (route) => ({
        instance: stores.modules,
      }),
    },
    {
      component: ShowModule,
      name: 'modules.show',
      path: '/modules/:id/edit',
    },
    {
      component: AppSetting,
      name: 'app.setting',
      path: '/setting/app',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: MatomoSetting,
      name: 'analytic.setting',
      path: '/setting/analytic',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: TimeServices,
      name: 'time.settings',
      path: '/setting/time',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: Other,
      name: 'other.setting',
      path: '/setting/other',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: EpgSources,
      name: 'epg.setting',
      path: '/setting/epg',
    },
    {
      component: GeneralSetting,
      name: 'general.setting',
      path: '/setting/general',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: SystemService,
      name: 'system.services',
      path: '/system/services',
    },
    {
      component: MailConfiguration,
      name: 'general.mail.configuration',
      path: '/mail/configuration',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: PaymentsConfiguration,
      name: 'general.payment.settings',
      path: '/payment/settings',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: SmsConfiguration,
      name: 'general.sms.configuration',
      path: '/sms/configuration',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: Configuration,
      name: 'setting',
      path: '/setting',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: PromotionsIndex, name: 'promotions.index', path: '/promotions',
      meta: {permission: 'guest-flow-type.PROMOTIONS'},
    }, {
      component: Promotions, name: 'create.promotion', path: '/promotions/create',
    }, {
      component: ShowPromotions, name: 'promotions.show', path: '/promotions/:id',
    },
    {
      component: InterestsIndex,
      name: 'interests.index',
      path: '/interests',
      meta: {permission: 'guest-flow-type.BASIC_PROFILING'},
    }, {
      component: ItinerariesIndex,
      name: 'itineraries.index',
      path: '/itineraries',
      meta: {permission: 'guest-flow-type.ACCOMODATION_LIBRARY'},
    }, {
      component: RatingsIndex,
      name: 'ratings.index',
      path: '/ratings',
      meta: {permission: 'guest-flow-type.RATINGS'},
    }, {
      component: GuestFlowModuleShow,
      name: 'fastCheckIn',
      path: '/fast-check-in',
      props: {moduleId: 3},
      meta: {permission: 'guest-flow-type.FAST_CHECK_IN'},
    }, {
      component: GuestFlowModuleShow,
      name: 'myMood',
      path: '/my-mood',
      props: {moduleId: 5},
      meta: {permission: 'guest-flow-type.EMOTION_RECOGNITION'},
    }, {
      component: SkipCleaningIndex,
      name: 'skipCleaning',
      path: '/skip-cleaning',
      props: {moduleId: 9},
      meta: {permission: 'guest-flow-type.SAVE_PLANET'},
    }, {
      component: ItemsIndexGenericMultiTable,
      name: 'manageproperty',
      path: '/manage-property',
      props: (route) => {
        const instance1 = stores.Assets;
        const instance2 = stores.AssetCategory;
        return {
          instance: {
            assets: instance1, assetsCategory: instance2, routeName: 'manageProperty',
          },
        };
      },
      meta: {permission: 'feature.PROPERTY_ASSETS'},
    },
    {
      component: PropertyIndex,
      name: 'rooms',
      path: '/rooms',
      meta: {permission: 'feature.PROPERTY_ASSETS'},
    },

    {
      component: ItemsIndexGeneric,
      name: 'assetCategory',
      path: '/assets/category',
      props: (route) => ({
        instance: stores.AssetCategory,
      }),
    },
    {
      component: ItemsIndexGeneric,
      name: 'contactModule',
      path: '/contact/module',
      props: (route) => ({
        instance: stores.ContactModule,
      }),
      meta: {permission: 'module-type.CONTACTS'},
    },
    {
      component: ContactModule,
      name: 'contactModule.show',
      path: '/contact/module/:id',
      meta: {permission: 'module-type.CONTACTS'},
    },
    {
      component: AssetsCategoryShow,
      name: 'assetCategory.show',
      path: '/assets/category/:id',
      meta: {permission: 'feature.PROPERTY_ASSETS'},
    },
    {
      component: ItemsIndexGenericMultiTable,
      name: 'locations',
      path: '/rooms',
      props: (route) => {
        const instance1 = stores.Rooms;
        const instance2 = stores.RoomType;
        return {
          instance: {
            zones: instance1,
            zoneCategory: instance2,
            routeName: 'location',
          },
        };
      },
      meta: {permission: 'feature.PROPERTY_ASSETS'},
    },
    {
      component: LocationShow,
      name: 'zones.show',
      path: '/rooms/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'zoneCategory',
      path: '/category',
      props: (route) => ({
        instance: stores.RoomType,
      }),
    },
    {
      component: LocationsCategoryShow,
      name: 'zoneCategory.show',
      path: '/category/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'library',
      path: '/library',
      props: (route) => ({
        instance: stores.Library,
      }),
    },
    {
      component: LibraryShow,
      name: 'library.show',
      path: '/library/show/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'ratingPlatform',
      path: '/rating/plateform',
      props: (route) => ({
        instance: stores.RatingPlatfrom,
      }),
    },
    {
      component: RatingPateformShow,
      name: 'ratingPlatform.show',
      path: '/rating/plateform/:id',
    },
    {
      component: SocialMediaIndex,
      name: 'socialMedia.index',
      path: '/social-media',
      meta: {permission: 'guest-flow-type.SOCIAL_MEDIA'},
    },
    {
      component: SocialMediaShow,
      name: 'socialMedia.show',
      path: '/social-media/:id',
      meta: {permission: 'guest-flow-type.SOCIAL_MEDIA'},
    },
    {
      component: ItemsIndexGeneric,
      name: 'commentProposal',
      path: '/comment-proposals',
      props: (route) => ({
        instance: stores.CommentProposal,
      }),
    },
    {
      component: CommentProposalsShow,
      name: 'commentProposal.show',
      path: '/comment-proposals/:id',
    },
    {
      component: AssetsShow,
      name: 'assets.show',
      path: '/assets/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'guestflowmodule',
      path: '/guest-flow-module',
      props: (route) => ({
        instance: stores.GuestFlow,
      }),
    },
    {
      component: GuestFlowModuleShow,
      name: 'guestflowmodule.show',
      path: '/guest-flow-module/:id',
      props: (route) => ({
        instance: stores.GuestFlow,
      }),
    },
    {
      component: DashboardsIndex,
      name: 'dashboards',
      path: '/dashboards',
    },
    {
      component: ItemsIndexGeneric,
      name: 'gfMobileDashboard',
      path: '/gf-mobile-dashboards',
      props: (route) => ({
        instance: stores.gFMobileComposer,
      }),
    },
    {
      component: ShowGFMobileComposer,
      name: 'gfMobileDashboard.show',
      path: '/composer/gfmobile/:id',
    },
    {
      component: CreateDashboard,
      name: 'dashboards.create',
      path: '/dashboards/create',
    },
    {
      component: ShowDashboard,
      name: 'dashboards.show',
      path: '/dashboards/:id',
    },
    {
      component: DeleteDashboard,
      name: 'dashboards.delete',
      path: '/dashboards/:id/delete/:type?',
    },
    {
      component: ShowMobileComposer,
      name: 'mobileDashboards.show',
      path: '/composer/mobile/:id',
      meta: {permission: 'feature.MOBILE_DASHBOARDS'},
    },
    {
      component: ShowSTBComposer,
      name: 'stbDashboards.show',
      path: '/composer/stb/:id',
      meta: {permission: 'feature.STB_DASHBOARDS'},
    },
    {
      component: CreateDashboardPanel,
      name: 'dashboards-panel.create',
      path: '/dashboard-panel/create/:id',
    },
    {
      component: ShowPanel,
      name: 'dashboard-panel.show',
      path: '/dashboard-panel/:id',
    },
    {
      component: DeletePanel,
      name: 'dashboards-panel.delete',
      path: '/dashboards/:id/panel/:panelId/delete/:type?',
    },
    {
      component: CreatePanelElement,
      name: 'panel.element.create',
      path: '/panel-element/:id/create',
    },
    {
      component: ShowPanelElement,
      name: 'panel.element.show',
      path: '/panel-element/:id/:type?',
    },
    {
      component: DeletePanelElement,
      name: 'panel.element.delete',
      path: '/panel-element/:id/delete',
    },
    {
      component: Localization,
      name: 'localization',
      path: '/localization',
    },
    {
      component: ItemsIndexGeneric,
      name: 'languages',
      path: '/languages',
      props: (route) => ({
        instance: stores.Language,
      }),
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: ShowLanguage,
      name: 'languages.show',
      path: '/languages/:id',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: PackageCreate,
      name: 'packages.create',
      path: '/packages/create',
    },
    {
      component: PackageDelete,
      name: 'packages.delete',
      path: '/packages/:id/delete',
    },
    {
      component: PackageDetails,
      name: 'packages.show',
      path: '/packages/:id',
      meta: {permission: 'feature.PACKAGES'},
    },
    {
      component: ItemsIndexGeneric,
      name: 'packages',
      path: '/packages',
      props: (route) => ({
        instance: stores.Package,
      }),
      meta: {permission: 'feature.PACKAGES'},
    },
    {
      component: ItemsIndexGeneric,
      name: 'messaging',
      path: '/messaging',
      props: (route) => ({
        instance: stores.Messaging,
      }),
      meta: {permission: 'feature.MESSAGING'},
    },
    {
      component: UserSettings,
      name: 'user.settings',
      path: '/userSettings',
      meta: {permission: 'feature.USER_SETTINGS'},
    },
    {
      component: DigitalSignageIndex,
      name: 'digitalSignage.index',
      path: '/digitalSignage',
      meta: {permission: 'module-type.DIGITAL_SIGNAGE'},
    },
    {
      component: RSSIndex,
      name: 'rss.index',
      path: '/rss',
      meta: {permission: 'module-type.RSS'},
    },
    {
      component: CurrencyExchangeIndex,
      name: 'currencyExchange.index',
      path: '/currency-exchange',
      meta: {permission: 'module-type.CURRENCY_EXCHANGE'},
    },
    {
      component: ChatIndex,
      name: 'chat.index',
      path: '/chat',
      meta: {permission: 'feature.CHAT'},
    },
    {
      component: MoodsIndex,
      name: 'moods.Index',
      path: '/moods',
      meta: {permission: 'guest-flow-type.EMOTION_RECOGNITION'},
    },
    {
      component: SurveysIndex,
      name: 'surveys.index',
      path: '/surveys',
      meta: {permission: 'module-type.SURVEYS'},
    },
    {
      component: SurveyQuestionsExport,
      name: 'surveys.questions.export',
      path: '/surveys/questions/:id/export',
      meta: {permission: 'module-type.SURVEYS'},
    },
    {
      component: WelcomeScreenIndex,
      name: 'welcomeScreen.index',
      path: '/welcomeScreen',
      meta: {permission: 'feature.WELCOME_SCREEN'},
    },
    {
      children: [
        {
          component: ItemsIndexGeneric,
          name: 'clock',
          path: 'clock',
          props: (route) => ({
            moduleId: parseInt(route.params.moduleId, 10),
          }),
        },
        {
          component: ModuleIndex,
          name: 'time.index',
          path: '',
        },
      ],
      component: ModuleView,
      path: '/time/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'TIME',
      }),
      meta: {permission: 'module-type.TIME'},
    },
    {
      children: [
        {
          component: WeatherLocationsCreate,
          name: 'locations.create',
          path: 'locations/create',
        },
        {
          component: ItemsIndexGeneric,
          name: 'locations',
          path: 'locations',
        },
        {
          component: ModuleIndex,
          name: 'weather.index',
          path: '',
        },
      ],
      component: ModuleView,
      path: '/weather/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'WEATHER',
      }),
      meta: {permission: 'module-type.WEATHER'},
    },
    {
      children: [
        {
          component: CatalogueShow,
          name: 'catalogue.items.show',
          path: 'item/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'catalogue.items',
          path: 'catalogue_items',
        },
        {
          component: CategoriesShowMulti,
          name: 'catalogue.categories',
          path: 'categories/:id?',
        },
        {
          component: ModuleIndex,
          name: 'catalogue.index',
          path: '',
        },
      ],
      component: ModuleView,
      path: '/catalogue/:moduleId',
      props: (route: any) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'CATALOGUE',
      }),
      meta: {permission: 'module-type.CATALOGUE'},
    },
    {
      children: [
        {
          component: ServiceShow,
          name: 'service.items.show',
          path: 'item/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'service.items',
          path: 'service_items',
        },
        {
          component: CategoriesShowMulti,
          name: 'service.categories',
          path: 'service/:id?',
        },
        {
          component: ServiceCategoryShow,
          name: 'service.categories.show',
          path: 'categories/:id',
        },
        {
          component: ModuleIndex,
          name: 'service.index',
          path: '',
        },
      ],
      component: ModuleView,
      path: '/service/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'SERVICE',
      }),
      meta: {permission: 'module-type.SERVICE'},
    },
    {
      children: [
        {
          component: ApplicationsShow,
          name: 'applications.items.show',
          path: 'item/:id',
        },
        {
          component: ApplicationsShow,
          name: 'website_applications.items.show',
          path: 'item/:id',
        },
        {
          component: ApplicationsShow,
          name: 'tv_applications.items.show',
          path: 'item/:id',
        },
        {
          component: ApplicationsShow,
          name: 'ship_position_applications.items.show',
          path: 'item/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'applications.items',
          path: 'applications_items',
        },
        {
          component: CategoriesIndexBasic,
          name: 'applications.categories',
          path: 'categories',
          props: (route) => ({
            tableName: 'application_categories',
          }),
        },

        {
          component: CategoriesShowBasic,
          name: 'applications.categories.show',
          path: 'categories/:id',
        },
        {
          component: ModuleIndex,
          name: 'applications.index',
          path: '',
        },
        {
          component: ModuleIndex,
          name: 'tv_applications.index',
          path: '',
        },
      ],
      component: ModuleView,
      path: '/applications/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'APPLICATION',
      }),
      meta: {permissions: ['module-type.WEBSITE_APPLICATION', 'module-type.TV_APPLICATION']},
    },
    {
      component: ProfileCreate,
      name: 'profile.create',
      path: '/accounts/:id/profile/create',
    },
    {
      component: ProfileDelete,
      name: 'accounts.profile.delete',
      path: '/accounts/:acc/profile/:id/delete',
    },
    {
      component: ProfileShow,
      name: 'accounts.profile.show',
      path: '/accounts/:acc/profile/:id',
      meta: {permission: 'feature.CUSTOMERS'},
    },
    {
      component: UsersCreate,
      name: 'users.create',
      path: '/users/create',
    },
    {
      component: UsersDelete,
      name: 'users.delete',
      path: '/users/:id/delete',
    },
    {
      component: UsersShow,
      name: 'users.show',
      path: '/users/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'users',
      path: '/users',
      props: (route) => ({
        instance: stores.User,
      }),
    },
    {
      component: Logout,
      name: 'logout',
      path: '/logout',
    },
    {
      component: AdminDelete,
      name: 'admin.delete',
      path: '/admins/:id/delete',
    },
    {
      component: AccessRoles,
      name: 'access.roles',
      path: '/access/roles',
      meta: {permission: 'feature.ADMINS'},
    },
    {
      component: Admins,
      name: 'admins',
      path: '/admins',
      meta: {permission: 'feature.ADMINS'},
    },
    {
      component: AdminsCreate,
      name: 'admins.create',
      path: '/admins/create',
      meta: {permission: 'feature.ADMINS'},
    },
    {
      component: AdminsShow,
      name: 'admins.show',
      path: '/admins/:id',
      meta: {permission: 'feature.ADMINS'},
    },
    {
      component: CustomRoles,
      name: 'customRoles',
      path: '/custom-roles',
      meta: {permission: 'feature.ADMINS'},
    },
    {
      component: CustomRolesCreate,
      name: 'customRoles.create',
      path: '/custom-roles/create',
      meta: {permission: 'feature.ADMINS'},
    },
    {
      component: CustomRolesShow,
      name: 'customRoles.show',
      path: '/custom-roles/:id',
      meta: {permission: 'feature.ADMINS'},
    },
    {
      component: CustomRolesDelete,
      name: 'customRoles.delete',
      path: '/customRoles/:id/delete',
      meta: {permission: 'feature.ADMINS'},
    },
    {
      component: TVModuleCreate,
      name: 'modules.create',
      path: '/modules/create',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'TV_RADIO',
      }),
    },
    {
      component: EpgSources,
      name: 'epgSources',
      path: '/epg/sources',
      meta: {permission: 'module-type.TV_RADIO'},
    },
    {
      component: EpgSourcesCreate,
      name: 'epgSources.create',
      path: '/epg/sources/create',
      meta: {permission: 'module-type.TV_RADIO'},
    },
    {
      component: ItemsIndexGeneric,
      name: 'accounts',
      path: '/accounts',
      props: (route) => ({
        instance: stores.accounts,
      }),
      meta: {permission: 'feature.CUSTOMERS'},
    },
    {
      component: AccountsCreate,
      name: 'accounts.create',
      path: '/accounts/create',
    },
    {
      component: AccountsShow,
      name: 'accounts.show',
      path: '/accounts/:id',
      meta: {permission: 'feature.CUSTOMERS'},
    },
    {
      component: AccountDelete,
      name: 'accounts.delete',
      path: '/accounts/:id/delete',
    },
    {
      component: Media,
      name: 'media.folder',
      path: '/media/folder/:folderId',
      meta: {permission: 'feature.MEDIA_LIBRARY'},
    },
    {
      component: FolderDelete,
      name: 'media.folder.delete',
      path: '/media/folder/:folderId/delete',
    },
    {
      component: MediaShow,
      name: 'media.file.show',
      path: '/media/file/:fileId',
      meta: {permission: 'feature.MEDIA_LIBRARY'},
    },
    {
      component: FileDelete,
      name: 'media.file.delete',
      path: '/media/file/:fileId/delete',
    },
    {
      component: File,
      name: 'file.library',
      path: '/file/library',
      meta: {permission: 'feature.SETTINGS'},
    },
    {
      component: FileShow,
      name: 'file.library.show',
      path: '/file/library/:fileId',
    },
    {
      children: [
        {
          component: RecordersStreamCreate,
          name: 'recorders.stream.create',
          path: '/recorders/:id/create',
        },
        {
          component: RecordersCreate,
          name: 'recorder.create',
          path: 'create',
        },
        {
          component: RecordersShow,
          name: 'recorder.show',
          path: 'recording/:id',
        },
        {
          component: Recorders,
          name: 'recorders',
          path: 'index',
        },
      ],
      component: ModuleView,
      path: '/recorders/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'RECORDER',
      }),
    },
    {
      children: [
        {
          component: TranscoderStreamsCreate,
          name: 'transcoder.streams.create',
          path: 'streams/create',
        },
        {
          component: TranscoderStreamsShow,
          name: 'transcoder.streams.show',
          path: 'streams/:id',
        },
        {
          component: TranscoderStreams,
          name: 'transcoder.streams',
          path: 'streams',
        },
      ],
      component: ModuleView,
      path: '/transcoder/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'TRANSCODER',
      }),
    },
    {
      children: [
        {
          component: CategoriesIndexBasic,
          name: 'video.categories',
          path: 'categories',
          props: (route) => ({
            tableName: 'vod_categories',
          }),
        },
        {
          component: CategoriesShowBasic,
          name: 'video.categories.show',
          path: 'categories/:id',
        },
        {
          component: VODMovieShow,
          name: 'video.items.show',
          path: 'movies/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'video.items',
          path: 'movies',
        },
        {
          component: ModuleIndex,
          name: 'video.index',
          path: 'index',
        },
      ],
      component: ModuleView,
      path: '/video/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'VIDEO',
      }),
      meta: {permission: 'module-type.VIDEO'},
    },
    {
      component: DevicesIndex,
      name: 'devices.index',
      path: '/devices',
      props: (route) => {
        const instance1 = stores.Devices;
        const instance2 = stores.DeviceGroups;
        return {
          instance: {
            assets: instance1,
            assetsCategory: instance2,
            routeName: 'devices',
          },
        };
      },
      meta: {permission: 'feature.PROPERTY_ASSETS'},
    },
    {
      component: DeviceGroupShow,
      name: 'deviceGroup.show',
      path: '/device-group/:id',
      meta: {permission: 'feature.PROPERTY_ASSETS'},
    },
    {
      component: DevicesCreate,
      name: 'devices.create',
      path: '/devices/create',
    },
    {
      component: DevicesShow,
      name: 'device.show',
      path: '/devices/:id',
      meta: {permission: 'feature.PROPERTY_ASSETS'},
    },
    {
      component: Home,
      name: 'home',
      path: '/dashboard',
    },
    {
      children: [
        {
          component: CategoriesShowBasic,
          name: 'tv.categories.show',
          path: 'categories/:id',
        },
        {
          component: CategoriesIndexBasic,
          name: 'tv.categories',
          path: 'categories/',
          props: (route) => ({
            tableName: 'tv_categories',
          }),
        },
        {
          component: TVChannelsDelete,
          name: 'tv.items.delete',
          path: 'channels/:id/delete',
        },
        {
          component: TVChannelsShow,
          name: 'tv.items.show',
          path: 'channels/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'tv.items',
          path: 'channels/',
        },
        {
          component: ModuleIndex,
          name: 'tv.index',
          path: '',
        },
      ],
      component: ModuleView,
      name: 'tv',
      path: '/tv/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'TV_RADIO',
      }),
      meta: {permission: 'module-type.TV_RADIO'},
    },
    {
      children: [
        {
          component: episodesShow,
          name: 'episodes.show',
          path: 'series/:seriesId?/seasons/:seasonsId?/episodes/:id?',
          props: (route) => ({
            id: parseInt(route.params.id, 10),
            seriesId: parseInt(route.params.seriesId, 10),
          }),
        },
        {
          component: episodesShow,
          name: 'trailor.show',
          path: 'series/:seriesId?/episodes/:id?',
        },
        {
          component: Crew,
          name: 'crews.show',
          path: '/crew/:crewId',
        },
        {
          component: Cast,
          name: 'casts.show',
          path: '/cast/:castId',
        },
        {
          component: seasionsShow,
          name: 'seasons.show',
          path: 'series/:seriesId/seasons/:id',
          props: (route) => ({
            seriesId: parseInt(route.params.seriesId, 10),
          }),
        },
        {
          component: TVSeriesShow,
          name: 'series.items.show',
          path: 'series/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'series.items',
          path: 'series/',
        },
        {
          component: CategoriesIndexBasic,
          name: 'series.categories',
          path: 'category/',
          props: (route) => ({
            tableName: 'genres',
          }),
        },
        {
          component: CategoriesShowBasic,
          name: 'series.categories.show',
          path: 'categories/:id',
        },
        {
          component: ModuleIndex,
          name: 'series.index',
          path: '',
        },
      ],
      component: ModuleView,
      path: '/series/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'SERIES',
      }),
      meta: {permission: 'module-type.SERIES'},
    },
    {
      children: [
        {
          component: trailerShow,
          name: 'mod.trailor.show',
          path: 'movie/:movieID/episodes/:id?',
        },
        {
          component: MODShow,
          name: 'mod.items.show',
          path: 'movie/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'movie.items',
          path: 'movie/',
        },
        {
          component: CategoriesIndexBasic,
          name: 'mod.categories',
          path: 'category/',
          props: (route) => ({
            tableName: 'genres',
          }),
        },
        {
          component: CategoriesShowBasic,
          name: 'mod.categories.show',
          path: 'categories/:id',
        },
        {
          component: ModuleIndex,
          name: 'mod.index',
          path: '',
        },
      ],
      component: ModuleView,
      path: '/module/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'MOD',
      }),
      meta: {permission: 'module-type.MOD'},
    },
    {
      component: Customer,
      name: 'guests',
      path: '/guests',
      meta: {permission: 'feature.CUSTOMERS'},
    },
    {
      component: CustomersCreate,
      name: 'customers.create',
      path: '/guests/create',
    },
    {
      component: CustomersShow,
      name: 'customers.show',
      path: '/guests/:id',
      meta: {permission: 'feature.CUSTOMERS'},
    },
    {
      component: MessageLogShow,
      name: 'messageslog.show',
      path: '/message/:id',
      meta: {permission: 'feature.CUSTOMERS'},
    },
    {
      component: CustomersDelete,
      name: 'customers.delete',
      path: '/guests/:id/delete',
    },
    {
      component: ItemsIndexGeneric,
      name: 'stays',
      path: '/stays',
      props: (route) => ({
        instance: stores.Stays,
      }),
    },
    {
      component: StaysShow,
      name: 'stays.show',
      path: '/stays/:id',
    },
    {
      component: ItemsIndexGeneric,
      name: 'stayTypes',
      path: '/stay/category',
      props: (route) => ({
        instance: stores.StayTypes,
      }),
    },
    {
      component: StaysTypesShow,
      name: 'stayTypes.show',
      path: '/stay/category/:id',
    },
    {
      component: AddressCreate,
      name: 'customers.address.create',
      path: '/guests/:id/address/create',
    },
    {
      component: AccountsCreate,
      name: 'customer.account.create',
      path: '/guests/:id/account/create',
    },
    {
      component: DeviceTypes, // TODO: MAKS: tole anžetu ful ni všeč da je tkole z minusom routa, probi dat nazaj na /devices/types a hkrati se mora samo izbrana pobarvat, nazadne nism najdu css-a v routerju :(
      name: 'devices.types',
      path: '/device-types',
      meta: {permission: 'feature.PROPERTY_ASSETS'},
    },
    {
      component: DeviceTypesShow,
      name: 'deviceType.show',
      path: '/device-types/:id',
      meta: {permission: 'feature.PROPERTY_ASSETS'},
    },
    {
      component: DevicyTypesDelete,
      name: 'devices.types.delete',
      path: '/device-types/:id/delete',
    },
    {
      component: DeviceTypesCreate,
      name: 'devices.types.create',
      path: '/device-types/create',
    },
    {
      children: [
        // {
        //   component: aodTrailerShow,
        //   name: 'aod.trailor.show',
        //   path: 'movie/:movieID/episodes/:id?',
        // },
        {
          component: Crew,
          name: 'crews.show',
          path: '/crew/:crewId',
        },
        {
          component: Cast,
          name: 'casts.show',
          path: '/cast/:castId',
        },
        {
          component: AODShow,
          name: 'aod.items.show',
          path: 'item/:id',
        },
        {
          component: ItemsIndexGeneric,
          name: 'aod.items',
          path: 'items/',
        },
        {
          component: CategoriesIndexBasic,
          name: 'aod.categories',
          path: 'category/',
          props: (route) => ({
            tableName: 'aod-category',
          }),
        },
        {
          component: aodCategoryEdit,
          name: 'aod.categories.show',
          path: 'categories/:id',
        },
        {
          component: ModuleIndex,
          name: 'aod.index',
          path: '',
        },
      ],
      component: ModuleView,
      path: '/aod/:moduleId',
      props: (route) => ({
        moduleId: parseInt(route.params.moduleId, 10),
        moduleType: 'AOD',
      }),
      meta: {permission: 'module-type.AOD'},
    },
  ],
});


router.beforeEach(async (to, from, next) => {
  if (to.meta?.public) {
    next();
    return;
  }
  if(to.name === 'admin.2FA' || to.name === '2FA')
  {
    next();
    return;
  }

  try {
    await ensureInitialAdminData();

    if (to.path.startsWith('/admin') && stores.admin.isLoggedInWithoutOTPVerification) {
      next({name: 'admin.2FA'});
      return;
    }
    if (router.options.base !== '/' && stores.admin.isLoggedInWithoutOTPVerification) {
      next({name: '2FA'});
      return;
    }
    // uglish fix for checking permissions - broken after NEV-911
    if (to.path.startsWith('/admin')) {
      let permissions  = to.meta?.permission || [];
      permissions = Array.isArray(permissions) ? permissions : [permissions];

      permissions.forEach((permission: string) => {
        console.log(permission)
        if (!hasPermission(permission)) {
          next({name: '401'});
        }
      });
    }

  } catch (e) {
    console.error(e)
    if (router.options.base !== '/') {
      next({
        path: '/login', query: {redirect: to.fullPath},
      });
      return;
    }

    if (to.path.startsWith('/admin')) {
      next({name: 'admin.login'});
      return;
    }

    if (globalConfig.env !== 'development') {
      next({name: '404'});
      return;
    }

    next({name: 'projects'});
    return;
  }

  if (canNavigateTo(to.name!)) {
    next();
    return;
  } else {
    next({name: '401'});
  }

  // prevent disabled scroll
  document.body.style.overflow = 'auto';

});
export default router;
