import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';
import Axios from "axios";

let cancel: any;
const CancelToken: any = Axios.CancelToken;

const URL = '/rss';


export class RSSFeed extends Model<IRSSFeed> implements IRSSFeed {
  id: number | null = null;
  name: string = '';
  link: string = '';
  imageId!: number | null;
  image!: IMediaItem | null;


  constructor(attributes: Partial<IRSSFeed>, parentCollection?: Collection) {
    super(attributes, parentCollection);
    Object.assign(this, attributes);
  }

  urlRoot(): string {
    return URL;
  }
}

export default class RSSFeedCollection extends Collection<RSSFeed> {

  routeName = 'rss';

  url(): string {
    return URL;
  }

  model(): new (attributes: IRSSFeed, collection?: Collection) => RSSFeed {
    return RSSFeed;
  }

  build(attributes: IRSSFeed): RSSFeed {
    return new RSSFeed(attributes, this);
  }

  deleteItem(id: number) {
    return axiosInstance.delete(`${this.url()}/${id}`)
      .then((response) => response.data);
  }

  deleteItems(ids: any[]) {
    return axiosInstance.post(`${this.url()}/delete`, {ids})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  fetchData(index: number = 1, search: string | null = null, perpage: number = 10) {
    if (typeof cancel === 'function') {
      cancel();
    }

    const cancelToken = new CancelToken(function executor(c: any) {
      cancel = c;
    });

    const baseURL = `${this.url()}?page=${index}&perpage=${perpage}`;
    const url = search ? `${baseURL}&search=${search}` : baseURL;

    return axiosInstance.get(url, {cancelToken})
      .then((response) => response.data.data)
      .catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }

  createNewItem(feed: IRSSFeed) {
    return axiosInstance.post(`${this.url()}`, feed)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }

  updateItem(feed: IRSSFeed) {
    return axiosInstance.put(`${this.url()}/${feed.id}`, feed)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
