
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {bus, translate} from "../../helpers/functions";
import stores from "@/stores";
import {showToaster} from "@/components/DashboardComposer/helpers";
import NevronNewSourcePopup from "@/components/NevronNewSourcePopup.vue";
import AttachImage from "@/modules/Media/Attach.vue";

@Component({
  components: {AttachImage, NevronNewSourcePopup},
  methods: {translate}
})
export default class MediaSources extends Vue {
  @Prop({default: null})
  instance: any;

  deviceTypes: IDeviceType[] = [];
  sourceTypes: ISourcesType[] | null = null;
  authorizationTypes: IAuthorizationType[] = [];
  selectedSourceIndex!: number;
  sourceToEdit: IChannelSource | null = null;

  mounted() {
    this.getSourceData();
  }

  onMediaTypeClick(type: string) {
    if (type === 'media-library') {
      this.openMediaLibrary();
    } else {
      this.checkStreamUrl();
    }
  }
  openMediaLibrary() {
    // @ts-ignore;
    this.$refs.updateSourceUrl.$children[0].open();
  }
  updateSourceUrl(source: any) {
    if( ! source.result.isImageFile &&  ! source.result.isVideoFile)
    {
      showToaster('danger', '',translate('unsupported-file-type'), 400);
      return
    }
    if(source.result.imageUrl)
    {
      this.sourceToEdit!.source = source.result.imageUrl;
    }
    if(source.result.videoUrl)
    {
      this.sourceToEdit!.source = source.result.videoUrl;
    }
  }

  checkStreamUrl() {
    this.sourceToEdit!.source = '';
  }

  get showTimeShiftOption(): boolean {
    return this.instance.module.type === 'TV_RADIO';
  }
  getSourceData(e: any = null) {
    if (e) {
      e.preventDefault();
    }

    Promise.all([
      stores.modules.getSourceTypes(),
      stores.modules.getVideoSourceTypes(),
      stores.modules.getAuthorizationTypes()
    ]).then(([sourceTypes, videoSourceTypes, authorizationTypes]) => {
      this.deviceTypes = sourceTypes;
      this.sourceTypes = videoSourceTypes;
      this.authorizationTypes = authorizationTypes;

      bus().$emit('loadSourcePopup', {
        deviceTypes: sourceTypes,
        videoSourceTypes: videoSourceTypes,
        authorizationTypes: authorizationTypes
      });
    });
  }

  async createNewSource(sources: ISource[]) {
    if (!this.instance || !this.instance.id) {
      return;
    }

    for (let index in sources) {
      // Check if the deviceTypeId already exists in the channel's sources
      // @ts-ignore
      const existedSource = this.instance.sources.find(source => source.deviceTypeId === sources[index].deviceTypeId);
      if (existedSource) {
        // @ts-ignore
        showToaster('warning', '', 'source already exists for ' + this.deviceTypeName(sources[index].deviceTypeId));
      } else {
        this.instance.sources.push(sources[index]);
      }
    }
  }

  private deviceTypeName(deviceTypeId: number) {
    const deviceType = this.deviceTypes.find(deviceType => deviceType.id === deviceTypeId);
    return deviceType ? deviceType.default : '';
  }

  selectedSource(sourceIndex: number, source: IChannelSource) {
    this.selectedSourceIndex = sourceIndex;
    this.sourceToEdit = source;
  }

  deleteSource(sourceIndex: number): void {
    // @ts-ignore
    this.instance?.sources.splice(sourceIndex, 1);
  }
  editExistingSource(source: ISource) {
    if (!this.instance || !this.instance.sources || !Array.isArray(this.instance.sources) || !this.selectedSourceIndex) {
      console.error("Channel or sources are not initialized properly.");
      return;
    }
    this.sourceToEdit = null;
    // Update the object at the found index
    this.instance.sources[this.selectedSourceIndex] = { ...this.instance.sources[this.selectedSourceIndex], ...source };
  }


  cancelEdit() {
    this.sourceToEdit = null;
  }
}
