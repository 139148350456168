
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import { SweetModal } from 'sweet-modal-vue';
import GlobalFunctions from '@/GlobalFunctions';
import { showToaster } from '@/components/DashboardComposer/helpers';
import { SuperAdmin } from "@/stores/SuperAdmins";
import MultiSelect from 'vue-multiselect';
import globalConfig from "@/helpers/globalConfig";
import {RolesEnum} from "@/enums/RolesEnum";


@Component({
  components: {
    SweetModal,
    MultiSelect
  },
})
export default class SuperAdminUserCreate extends Vue {

  @Prop({ default: false })
  emitCreated!: boolean;

  @Prop()
  newuserIndex?: any;

  user = new SuperAdmin({});
  allRoles: string[] = [];
  allProjects: IProject[] = [];
  selectedProjects: IProject[] = [];
  allOperators: IOperator[] = [];
  rerenderKey = 0;
  globalFunctions = new GlobalFunctions();
  stores = stores;

  get isToShort() {
    if (0 < this.user.password.length && this.user.password.length < 8)
      return true;
  }

  get isSuperAdmin(): boolean {
    return stores.admin.role === RolesEnum.NEVRON_SUPERADMIN;
  }
  get isAllowedRemoveProject(): boolean {
    return this.isSuperAdmin && !this.user.futureProjects;
  }

  get isProjectAdmin(): boolean {
    return this.user.role === RolesEnum.PROJECT_ADMIN;
  }

  get filteredProjects(): IProject[] {
    return this.allProjects.filter(
      (project: any) => !this.selectedProjects.some((selected: any) => selected.id === project.id)
    );
  }

  get isInvalidConfirmation() {
    if (!this.user.password)
      return false;

    if (this.user.passwordConfirmation.length == 0)
      return false;

    return this.user.password !== this.user.passwordConfirmation;
  }

  async mounted() {
    let roles = await stores.SuperAdmins.getAllRoles();
    roles.forEach((role: IRole) => {
      this.allRoles.push(role.name as string);
    });

    let projects = await stores.master.getAllProjects();
    if(projects.length > 0)
    {
      this.allProjects.push({
        'id' : 'all_projects',
        'name' : 'All Projects'
      });
    }
    projects.forEach((project: IManagerProject) => {
      this.allProjects.push({ id : project.id, name : project.name as string });
    });

    if (globalConfig.showOperators) {
      stores.SuperAdminOperators.fetchData(1, '', 100, null).then((response: any) => {
        this.allOperators = response.data.data;
      })
        .catch((e: any) => {
          console.error(e);
        }).finally(() => {
        });
    } else {
      this.allOperators = [];
    }
  }

  onSubmit(): Promise<void | {}> {
    if (!this.validateForm()) {
      return Promise.resolve();
    }
    this.user.projects = this.selectedProjects;
    return stores.SuperAdmins.createNewItem(this.user)
      .then((response: any) => {
        if (response.success) {
          showToaster('success', '', 'User successfully created.');
          this.cancel();
          if (this.emitCreated) {
            this.$emit('created', response.data);
            return;
          }
        }
      })
      .catch((e: any) => {
        console.log({ e });
      });
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }

  clean() {
    this.user = new SuperAdmin({});
    this.rerenderKey++;
  }

  validateForm(): boolean {
    const form = this.$refs['createForm'] as HTMLFormElement;
    form.classList.add('was-validated');
    return form.checkValidity() && !this.isInvalidConfirmation;
  }
  projectSelected(project: any){
    if(project.id === 'all_projects')
    {
      this.selectedProjects = this.allProjects;
      this.selectedProjects = this.selectedProjects.filter((p: any) => p.id !== 'all_projects');
      return;
    }
  }
  toggleAccessToCurrentFutureProjects(): void {
    if (this.user.futureProjects) {
      this.selectedProjects = this.allProjects.filter((project: any) => project.id !== 'all_projects');
    }
  }
  projectRemoved(project: IProject)
  {
    const selectedProject = this.allProjects.find((p: any) => p.id === project.id);
    if(selectedProject)
    this.selectedProjects.push(selectedProject);
  }
  removeSelectedProject(project: IProject)
  {
    this.selectedProjects = this.selectedProjects.filter((p: any) => p.id !== project.id);
  }
}
