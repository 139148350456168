
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
import stores from '@/stores';
// @ts-ignore
import i18n from '@/i18n';

@Component({
  components: {
    DynamicContent,
  },
})
export default class NevronInput extends Vue {
  @Prop() value!: string | null;
  @Prop() referenceKey!: string | '';
  @Prop({default: ''}) placeholder!: string;
  @Prop({default: () => []}) classes!: string[];
  @Prop({default: ''}) parentType!: string;
  @Prop({default: false}) checkTextEmpty!: boolean;
  @Prop({ default: () => ({}) }) inputAttributes!: Record<string, any>;
  @Prop({default: 1000000})
  limitText!: number;

  $languages: any = '';
  localKey: any = '';
  localText: any = '';

  mounted() {
    this.init();
  }

  handleTranslate() {
    if (this.parentType === 'model') {
      this.$emit('openTranslator', this.localText, this.localKey, this.referenceKey);
    } else {
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    }
  }

  @Watch('value')
  onValueChanged(newVal: any, oldVal: any) {
    this.init();
  }

  @Watch('localText')
  onLocalTextChanged(newVal: any, oldVal: any) {
    this.$emit('input', newVal);
  }

  init() {
    this.localKey = this.value;
    stores.Language.fetchingTranslationsPromise
      .then((resolved: any) => {
        if (i18n.te(this.localKey)) {
          this.localText = Vue.prototype.translate(this.localKey);
        } else {
          // if field was edited and translation was not saved yet
          this.localText = this.localKey;
        }
      });
  }

  onTextAreaInput() {
    // Workaround because pattern attr doesn't work on textarea
    if (this.checkTextEmpty && this.localText.trim() === '') {
      this.localText = '';
    }
  }

}
