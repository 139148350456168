
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import MultiSelect from 'vue-multiselect';
import NevronImageAndVideoField from '@/components/NevronImageAndVideoField.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import Editor from '@tinymce/tinymce-vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronInput from '@/components/NevronInput.vue';
import CreateSeasons from '../../../modules/Series/List/Seasons/Create.vue';
import CreateTrailor from '../../../modules/MOD/List/Episodes/create.vue';
import AttachCrew from './attachCrew.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import TablePopupModal from '@/components/NevronTablePopupModal.vue';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronAvailabilityOptions from "@/components/NevronAvailabilityOptions.vue";
import MODModule from '@/stores/modules/MOD';
import {goToModuleIndex} from "@/helpers/routing";
import { bus,translate } from '@/helpers/functions';
import NevronNewSourcePopup from "@/components/NevronNewSourcePopup.vue";
import MediaSources from "@/components/MediaContent/Sources.vue";

@Component({
  components: {
    NevronNewSourcePopup,
    virtualList,
    MediaSources,
    Editor,
    NevronHeader,
    NevronFooter,
    Skeleton,
    MultiSelect,
    NevronInput,
    CreateSeasons,
    CreateTrailor,
    AttachCrew,
    NevronImageAndVideoField,
    TablePopupModal,
    NevronAdvanceTextBox,
    AttachImage,
    NevronAvailabilityOptions
  },
})
export default class MoviesDetails extends Vue {
  @Prop()
  module!: MODModule;

  parentTye: string = 'series';
  episodeType: string = 'trailer';
  crewMetaKey: string = 'crewEdit';
  castMetaKey: string = 'castEdit';
  stores = stores;

  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  // @ts-ignore
  movies: ISeries | null = null;
  allCategories: ICategories[] = [];
  allPackages: IPackage[] = [];
  seasons: any[] = [];
  trailors: any[] = [];
  categoryTags: any[] = [];

  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;

  autocompleteItems: any[] = [];
  selectedFolderId: any = 1;
  contentUrl: any = '';
  catTag: string = '';

  itemName: string = '';
  editItem: any = '';
  pivotValue: any = '';
  imageSource: any = '';
  staffEmail: IItemSetting = {
    moduleId: this.module.id,
    itemId: this.movies?.id,
    key: 'staff_email',
    value: '',
    isTransalatable: false,
  };

  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.loadPage();
  }


  mediaSource(source: any) {
    if( ! source.result.isImageFile &&  ! source.result.isVideoFile)
    {
      showToaster('danger', '',translate('unsupported-file-type'), 400);
      return
    }
    if(source.result.imageUrl)
    {
      this.imageSource = source.result.imageUrl;
    }
    if(source.result.videoUrl)
    {
      this.imageSource = source.result.videoUrl;
    }
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.loadPage();
    this.itemName = this.module.itemsName;
  }

  loadPage() {
    this.getMovies(Number(this.$route.params.id));
    this.getPackages();
    this.getCategories();
    this.contentUrl = '';
  }

  itemSetting(settings: any) {
    for (const index of settings) {
      if (index.key == 'staff_email') {
        this.staffEmail.value = index.value;
      }
    }
  }

  loadFullPage() {
    this.loadPage();
  }

  imageSelected(image: IMediaItem) {
    if (this.movies) {
      this.movies.imageId = (image) ? image.id : null;
    }
  }

  /**
   * Delete the Movies from this account
   */
  deleteMovies() {
    return this.module.deleteSeries(this.movies?.id!)
      .then((response: any) => {
        if (!response.success) {
          throw new Error(response.error.message);
        }
        showToaster('success', Vue.prototype.translate(this.movies?.name), Vue.prototype.translate('successfully-deleted'));
        // @ts-ignore
        goToModuleIndex(this.module, {tab: 'list'});
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  detachCrew(selectedCrew: any, moviesId: number, e: any) {
    e.preventDefault();
    if (this.movies && confirm('Do you really want to detach this Crew?')) {
      return this.module.detachCrew(selectedCrew, moviesId)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  detachCast(selectedCast: any, moviesId: number, e: any) {
    e.preventDefault();
    if (this.movies && confirm('Do you really want to detach this Crew?')) {
      return this.module.detachCast(selectedCast, moviesId)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  detachTrailer(id: any, moviesId: number, e: any) {
    e.preventDefault();
    if (this.movies && confirm('Do you really want to delete this Trailor?')) {
      return this.module.detachTrailer(this.parentTye, this.movies.id, id)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  openEpisodeModel() {
    this.episodeType = 'trailer';
    localStorage.setItem('episodeTypes', 'trailer');
    // @ts-ignore
    this.$refs.createEpisodes.$children[0].open();
  }

  attach(a: any) {
    // @ts-ignore
    this.application.tags.push({text: a.tag.text});
    this.catTag = '';
  }

  saveAndReload(item: ISeries) {
    const settings = [];
    settings.push(this.staffEmail);
    item.itemSettings = settings;

    return this.module.updateItem(item.id!, item)
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.getMovies(Number(this.$route.params.id));
          // @ts-ignore
          showToaster('success', 'successfully updated', Vue.prototype.translate(this.movies.name));
        } else if (response.responseCode === 201) {
          // @ts-ignore
          showToaster('danger', '', response.error.message, response.code);
        } else {
          // @ts-ignore
          showToaster('danger', Vue.prototype.translate(this.movies.name), 'Fail to update', response.code);
        }
        this.loadPage();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @Watch('module', {immediate: true})
  LoadpageDetail() {
    this.episodeType = 'trailer';
    this.loadPage();
  }

  getMovies(id: number) {
    return this.module.getSeriesDetails(id)
      .then((response) => {
        this.movies = response;
        this.movies?.genres?.forEach((category: ICategories) =>
          category.name = Vue.prototype.translate(category.name));
        this.movies?.packages?.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));
        this.itemSetting(response.itemSettings);
      })
      .catch((error) => {
        console.log((error));
      });
  }

  getPackages() {
    stores.Package.getAllPackages()
      .then((response: any) => {
        this.allPackages = response;
        this.allPackages.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));
      });
  }

  getCategories() {
    this.module.getAllCategories()
      .then((response: any) => {

        if (!response) {
          return;
        }

        this.allCategories = response.data;
        this.allCategories.forEach((category: ICategories) =>
          category.name = Vue.prototype.translate(category.name));
      });
  }

  updateCrewPosition(item: any) {
    return this.module.updateCrew(this.module.id, this.movies!.id!, this.pivotValue.id, item)
      .then((response) => {
        if (response.responseCode === 200) {
          showToaster('success', Vue.prototype.translate(this.pivotValue.name), 'Successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.pivotValue.name), 'Fail to update');
        }
        this.loadPage();
      });
  }

  updateCastPosition(item: any) {
    return this.module.updateCast(this.module.id, this.movies!.id!, this.pivotValue.id, item)
      .then((response) => {
        if (response.responseCode === 200) {
          showToaster('success', Vue.prototype.translate(this.pivotValue.name), 'Successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.pivotValue.name), 'Fail to update');
        }
        this.loadPage();
      });
  }

  openCrewPositionPopup(item: any) {
    this.editItem = item.pivot.role;
    this.pivotValue = item;
    // @ts-ignore
    this.$refs.crewEditPopup.$children[0].open();
  }

}
